export const EN = {
  lang: 'en',
  code: 'en-US',
  bt_ok: 'Ok',
  bt_cancelar: 'Cancel',
  bt_fechar: 'Close',
  bt_back: 'Back',
  bt_apagar: 'Erase',
  bt_editar: 'Edit',
  bt_reordenar: 'Reorder',
  bt_salvar: 'Save',
  bt_novo: 'New',
  bt_atualizar: 'Update',
  bt_remover: 'Remove',
  bt_add: 'Add',
  bt_filtrar: 'Select',
  bt_selecionar: 'Select',
  bt_clonar: 'Clone',
  bt_enviar: 'Send',
  anterior: 'Previous',
  proximo: 'Next',
  items_pagina: 'Items per page',
  pagina: 'page',
  de: 'of',
  buscar: 'Search',
  msg_sim: 'Yes',
  msg_nao: 'No',
  salvar: 'Save',
  homem: 'Man',
  mulher: 'Woman',
  masculino: 'Male',
  feminino: 'Female',
  ambos: 'Both',
  veja_mais: 'More',
  recolher: 'Retract',
  carregando3pt: 'Loading...',
  pronto: 'Ready!',
  link_termos: 'https://supertreinosapp.com.br/termos-de-uso-en/',
  link_privacidade: 'https://supertreinosapp.com.br/politica-privacidade-en/',
  link_cancelamento: 'https://supertreinosapp.com.br/politica-de-cancelamento/',
  mais_opcoes: 'More options',
  opcoes: 'Options',

  detalhes: 'Details',
  dificuldade: 'Difficulty',
  iniciante: 'Beginner',
  intermediario: 'Intermediate',
  avancado: 'Advanced',
  expert: 'Expert',
  objetivo: 'Goal',
  semanas: 'Weeks',
  dias_por_semana: 'Days a week',
  tempo_treino: 'Training time',
  minutos: 'minutes',
  pontos_concluir: 'Points when finished',
  completo: 'completed',

  operacao_sucesso: 'A operação foi realizada com sucesso!',
  algo_errado: 'Something didn\'t work well. Try again.',
  indeterminado: 'Undetermined',
  conteudo_premium: 'Premium Content',
  trava_generica: 'Oops! This is content only available to <span class="name">Premium Subscribers</span>. Subscribe now!',
  trava_indisponivel: 'Oops! This content is no longer available! If you are interested in this content, contact our support to learn more.',

  fechar_app: 'Close',
  fechar_st: 'Close SuperTreinos',
  fechar_sc: 'Close ST Coach',
  fechar_sct: 'Close ST Coach Pro',
  certeza: 'Are your sure?',
  conta_blok: 'Your account is locked. Contact the administrator.',

  campo_obrigatorio: 'This field is required',
  campo_invalido: 'The value of this field is invalid',

  programas: 'Programs',
  programa: 'Program',
  treinos: 'Workouts',
  treino: 'Workout',
  exercicios: 'Exercises',
  exercicio: 'Exercise',
  biblioteca: 'Library',
  prescricoes: 'Recipes',
  nutricao: 'Nutrition',
  avaliacao: 'Assessment',

  recurso_exclusivo: 'This feature is exclusive to Standard, Pro or Black subscribers. Would you like to upgrade your plan?',
  recurso_exclusivo_black: '<center>This feature is exclusive to Pro or Black subscribers.</center><br><center>Would you like to upgrade your plan?</center>',
  recurso_exclusivo_black_only: 'This feature is exclusive to Black subscribers.',
  recurso_exclusivo_title: 'Exclusive Feature',
  gratis: 'Free',

  clique_arraste_reordenar: 'Click and drag to sort',
  ordernar_cima: 'Sort up',
  ordernar_baixo: 'Sort down',
  sexo: 'Sex',
  nome: 'Name',
  titulo: 'Title',
  subtitulo: 'Subtitle',
  antes_envio_poster: 'Before submitting a poster, add a TITLE',
  ou: 'Or',
  escolha_poster: 'Choose a poster',
  escolha_data: 'Choose a date',
  endereco_video: 'Video link',
  use_video_vimeo_youtube: 'Add videos from Youtube or Vimeo',
  descricao: 'Description',
  minutos_por_dia: 'Minutes per day',
  disponivel_planos: 'Available for plans',
  grupos_musculares: 'Muscle groups',

  dz_remover_imagem: 'REMOVE IMAGE',
  dz_cancelar_envio: 'CANCEL SENDING',
  dz_confirma_cancelar_envio: 'CONFIRM CANCEL SENDING?',
  dz_arquivo_invalido: 'INVALID FILE',

  nao_filtrar: 'Do Not Filter',
  filtros: 'Filters',
  limpar_filtros: 'Clear Filters',
  publicado: 'Published',
  oculto: 'Hidden',
  nao_publicado: 'Not published',
  reservado: 'Reserved',

  ativar: 'Activate',
  ativo: 'Active',
  status: 'Status',
  bloquear: 'Block',
  expiracao: 'Expiration',
  bloqueado: 'Blocked',
  cadastro: 'Register',
  historico: 'History',
  notas: 'Notes',
  arquivos: 'Files',

  aluno: 'customer',
  paciente: 'customer',
  dia: 'day',
  dias: 'days',
  peso: 'weight',

  limite_maximo:  'Sorry, you have reached the maximum limit of registrations allowed on this account. ' +
  'Please contact the support team for more information ' +
  'or upgrade your account.',
  limite_registros: 'Oops! You have reached the limit of registrations on your current account. Please upgrade or delete some old registrations.',
  link_copiado: 'The capture link has been copied',
  link_copiado_erro: 'Your system does not allow automatic copying. Please copy and paste manually.',

  por_alimentos: 'By foods',
  por_textos_livres: 'By free text',
  dieta: 'Diet',
  exportar_pdf: 'Export PDF',
  assinar_black: 'Black Subscription',

  link_transferencia: 'The link has been copied',
  copia_negada: 'Your system does not allow automated copying. Please copy and paste manually.',

  fsegunda: 'Monday',
  fterca: 'Tuesday',
  fquarta: 'Wednesday',
  fquinta: 'Thursday',
  fsexta: 'Friday',
  fsabado: 'Saturday',
  fdomingo: 'Sunday',

  // MENUS
  MenuUI: {
    menu_idioma: 'Language',
    menu_minhaconta: 'My Account',
    menu_suporte: 'Support',
    menu_sair: 'Exit',
    menu_dashboard: 'Dashboard',
    menu_programas: 'Programs',
    menu_alunos: 'Customers',
    menu_nutricao: 'Nutrition',
    menu_biblioteca: 'Library',
    menu_arquivos: 'Files',
    menu_loja: 'Store',
    menu_tutoriais: 'Tutorials',
    menu_apps: 'Apps',
    ptbr: 'Portuguese',
    enus: 'English',
    eses: 'Spanish',
    assinatura_pendente: 'Pending Signature',
    assinatura_pendente_regularize: 'Regularize your subscription within 3 days to avoid customers blocks',
    assinatura_pendente_gerenciar: 'Manage Subscription',
    assinatura_pendente_regularizada: 'Subscription successfully regularized!',
  },

  // LOGIN
  LoginPage: {
    hello: 'Hi',
    acesse: 'Login',
    email: 'E-mail',
    senha: 'Password',
    entrar: 'Login',
    proxima: 'Next',
    anterior: 'Previous',
    sem_conta: 'Do not have an account yet?',
    sem_conta_buscar_shape: 'Let\'s get fit!',
    digite_email: 'Type your email to login.',
    digite_senha: 'Type your password.',
    cadastrese: 'Register here!',
    criarconta: 'New account',
    gratis: 'It\' Free!',
    erro_login: 'Login/Password incorrect.',
    senha_invalida: 'Invalid password.',
    user_notfound: 'User not found.',
    login: 'Login',
    esqueceu: 'Forgot your password?',
    erro_login_user: 'User not found. Check if your login is correct.',
    erro_login_user_st: 'This account is only valid for use in the SuperTreinos app.',
    erro_login_user_sc: 'This account is only valid for use in the STCoach app.',
    erro_login_wait: 'User awaiting activation.',
    erro_login_block: 'Oops! Your access is blocked. Please contact your Personal Trainer.',
    erro_login_pwd: 'Incorrect password.',
    erro_login_empty: 'Username and/or password not provided.',
    erro_login_generic: 'Something didn\'t work right. Try again.',
  },

  // CADASTRO
  RegistrationPage: {
    cadastro: 'Register',
    ficha: 'Register and start training right now!',
    ficha_nutri: 'Cadastro para Nutricionistas. <br> Os campos com (*) são obrigatórios.',
    not_customer: 'Não é permitido cadastro de alunos.',
    nome: 'Name',
    email: 'E-mail',
    email_confirme: 'Confirme o E-mail',
    ddi: 'DDI',
    celular: 'phone number',
    nascimento: 'Birth date',
    sexo: 'Sex',
    homem: 'Man',
    mulher: 'Woman',
    senha: 'Password (minimum 6 digits)',
    confirme: 'Confirm Password',
    ao_cadastrar: 'By registering, I declare that I have read and accepted the',
    termos: 'terms of use',
    privacidade: 'privacy policy',
    vogal_e: 'and',
    cancelamento: 'cancellation policy',
    cadastrar: 'REGISTER',
    sucesso: 'Welcome [name]. Your registration was successful!',
    profissao: 'Profission',
    edfisica: 'Trainer',
    fisio: 'Physiotherapist',
    nutri: 'Nutritionist',
    estud: 'Student',
    cpf: 'Requirement for Brazilians only.',
    cref: 'Requirement for Brazilians only.',
    crefito: 'Requirement for Brazilians only.',
    crn: 'Requirement for Brazilians only.',
    nutriname: 'Requirement for Brazilians only.',
    cpf_not_valid: 'Requirement for Brazilians only.',
    cref_not_empty: 'Fill in your date of birth here',
    crefito_not_empty: 'Requirement for Brazilians only.',
    crn_not_empty: 'Requirement for Brazilians only.',
    nutriname_not_empty: 'Requirement for Brazilians only.',
    sou_estrangeiro: 'I am a foreigner and I declare that I am qualified to practice the profession in accordance with the regulations of my country.',
    senha_diferente: 'The passwords don\'t match!',
    email_diferente: 'The emails don\'t match!',
    escolha_prof: 'You must select at least one profession!',
    email_cadastrado: 'E-mail already exists! Please register another e-mail.',
    campo_obrigatorio: 'This field is required',
    campo_invalido: 'The value of this field is invalid',
    ddd: 'number',
  },

  // DASHBOARD
  DashboardPage: {
    assinatura: 'Plan',
    assinar: 'Sign it',
    vencimento: 'Expiration',
    renovar: 'Renew',
    lista_espera: 'Waiting list',
    nenhum_aluno_espera: 'No Customers Waiting',
    cliente: 'Customer',
    clientes: 'Customers',
    em_espera: 'Waiting',
    ver_lista: 'See list',
    veja_como_funciona: 'how it works',
    link_captacao: 'Capture Link',
    link_copiado: 'The capture link has been copied',
    link_copiado_erro: 'Your system does not allow automatic copying. Please copy and paste manually.',
    form_cadastro: 'Registration form: create your account',
    ver_todos: 'See all',
    alunos_cadastrados: 'Customer Registered',
    pacientes_cadastrados: 'Customers Status',
    ativos: 'Active',
    expirados: 'Expired',
    bloqueados: 'Blocked',
    clientes_premium: 'Premium Customers',
    tempo_consultoria: 'Consulting Time',
    pacientes: 'Customers',
    primeiro_aluno: 'Register your first customer',
    primeiro_paciente: 'Register your first customer',
    bloqueado: 'Blocked',
    perfil: 'Profile',
    no_phone: 'No phone',
    tel_nao_cadastrado: 'The customer\'s phone number was not registered!',
    clientes_nao_retornados: 'Customers not returned. Please try again.',
    cadastro_primeiro: 'Register your first training plan',
    pg_objetivos: 'Goals',
    pg_dificuldade: 'Level',
    pg_programas_cadastrados: 'Registered Programs',
    total_de: 'Total of',
    treinos_concluidos_fluxo: 'Workouts completed specifically in the Agenda function within the app',
    nenhum_treino_concluido: 'No Training Completed',
    ultimos_treinos: 'Latest Workouts',
    ver: 'See',
    ver_7: 'See 7 days',
    ver_15: 'See 15 days',
    ver_30: 'See 30 days',
    dias_sem_treinos_concluidos: 'Days without completed workouts are not displayed on the graph',
    ver_como_funciona: 'how it works',
    treinos_concluidos: 'Completed Workouts',
  },

  // PROGRAMAS
  ProgramasPage: {
    programas: 'Programs',
    treinos: 'Workouts',
    exercicios: 'Exercises',
    editar_programa: 'Edit the program',
    editar_treino: 'Edit the workout',
    editar_exercicio: 'Edit the exercise',
    click_enviar_banner: 'Click here to send the Program banner',
    arraste_banner: 'Drag the program banner here',
    click_enviar_capavideo: 'Click here to send the video thumbnail',
    arraste_capavideo: 'Drag the video thumbnail here',
    click_enviar_featured: 'Click here to send the highlight banner (1280px de largura por 720px de altura)',
    arraste_featured: 'Drag the highlight banner here (1280px de largura por 720px de altura)',
    atualizado_sucesso: 'Program updated!',
    cadastrado_sucesso: 'The Program was successfully registered!',
    limite_maximo:  'Sorry, you have reached the maximum limit of registrations allowed on this account. ' +
                    'Please contact the support team for more information ' +
                    'or upgrade your account.',
    limite_programas: 'Oops! You have reached the limit of programs on your current account. Please upgrade or delete some old programs.',
    bt_upgrade: 'UPGRADE',
    cadastro_programas: 'Program Registration',
    clone_confirmation: '<p>Some programs may take up to 15 minutes to copy.</p> <p>Are you sure you want to copy this Program?</p>',
    clone_confirmation_title: 'Clone Program',
    clone_confirmation_success: 'The Program is being cloned. This process may take some time, please check the cloned Program later.',
    apagar_programa: 'Are you sure you want to delete this Program? All Workouts and Exercises of this Program will be deleted too!',
    apagar_programa_title: 'Delete Program',
    apagar_programa_placeholder: 'write DELETE with Caps Lock on',
    apagar_programa_confirmacao: 'DELETE',
    apagar_programa_confirmacao_sucesso: 'The Program and all its Workouts and Exercises were successfully deleted!',
    apagar_clone: 'By stopping cloning, all Workouts and Exercises of this Program will be deleted.',
    apagar_clone_title: 'Stop cloning',
    apagar_clone_sucesso: 'Cloning was successfully stopped!',
    reordenar_programa_sucesso: 'Program successfully reordered!',
    reordenar_programas_sucesso: 'Programs successfully reordered!',
    help_video: '<p>If necessary, include an explanatory video of the program for the your customer.</p>' +
                '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Learn more</a></p>',
    help_clone: '<p>Due to the volume of content, an error may occur. Cancel.</p>' +
                '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Learn more</a></p>',
    banner_upload_sucesso: 'Program banner sent successfully!',
    thumb_upload_sucesso: 'Program thumbnail sent successfully!',
    menos_minuto: 'less than 1 minute',
    minutos: 'minutes',
    lista_programas_aluno: 'The Customer\'s Program list has been updated.',
    lista_arquivos_aluno: 'The Customer\'s Files List has been updated.',
    programa_listado: 'listed program',
    programas_listados: 'listed programs',
    disponiveis: 'available',
    como_criar_treinos: 'How to create workouts',
    processando_clonagem: 'cloning processing',
    aguarde_15min: 'Please wait up to 15 minutes.',
    atualize_tela: 'After this time, refresh the screen for the program to be displayed.',
    selecione_alunos: 'Set the customer for this program',
    nenhum_aluno: 'No customers registered',
    novo_aluno: 'new customer',
    enviar_todos: 'Send to all',
    selecionado: 'Selected',
    ocultado: 'Hidden',
    limpar_busca: 'Clear the Search to re-enable sorting',
    clique_arraste_reordenar: 'Click and drag to sort',
    ordernar_cima: 'Sort up',
    ordernar_baixo: 'Sort down',
    titulo: 'Title',
    antes_envio_poster: 'Before submitting a poster, add a TITLE to the Program.',
    poster_programa: 'Program Poster',
    ou: 'Or',
    escolha_poster: 'Choose a poster',
    detalhes: 'Details',
    endereco_video: 'Video link',
    use_video_vimeo_youtube: 'Add videos from Youtube or Vimeo',
    descricao: 'Description',
    objetivo: 'Goal',
    dias_por_semana: 'Days per week',
    minutos_por_dia: 'Minutes per day',
    selecione_capa: 'Select a poster for the Program',
    disponivel_planos: 'Available for plans',
  },

  // TREINOS
  TreinosPage: {
    click_enviar_banner: 'Click and send the workout banner',
    arraste_banner: 'Drag the workout banner here',
    atualizado_sucesso: 'Workout updated successfully!',
    cadastrado_sucesso: 'The Workout was registered successfully!',
    limite_maximo:  'Sorry, you have reached the maximum limit of registrations allowed on this account. ' +
                    'Please contact your system administrator for more information ' +
                    'or to request an update to your access level.',
    limite_programas: 'Oops! You have reached the limit of programs on your current account. Please upgrade or delete some old programs.',
    apagar_treino: 'Do you really want to delete this Workout? All Exercises in this Workout will be deleted too!',
    apagar_treino_title: 'Delete Workout',
    apagar_treinos: 'Do you really want to delete the selected Workout(s)? The Exercises of the selected Workout(s) will also be deleted!',
    apagar_treinos_title: 'Delete Workouts',
    apagar_treino_placeholder: 'write DELETE with caps lock on',
    apagar_treino_confirmacao: 'DELETE',
    apagar_treino_confirmacao_sucesso: 'The Workout and all its exercises have been successfully deleted!',
    title_innerhtml: '<img src="assets/imgs/icon-timer.svg" alt="">Choose the date for this workout to be released.',
    description: 'The scheduled workouts will be released and displayed automatically within the student\'s program.',
    label_date: 'Current Expiration:',
    reordenar_treino_sucesso: 'Program successfully reordered!',
    reordenar_treinos_sucesso: 'Programs reordered successfully!',
    recarregando_treinos: 'Reloading the workouts...',
    clone_treino_msg: 'The workouts will be cloned randomly below the originals.<br>Be careful to reorder them.',
    clone_treino_msg2: 'Workout limit reached. Delete a workout if you want to clone one.',
    clone_treino_title: 'Clone Workout',
    clone_treino_sucesso: 'Workout cloned successfully!',
    publicar_treino: 'The selected workouts will be available to the customers. Do you want to continue?',
    publicar_treino_title: 'Publish Workout',
    publicar_treino_sucesso: 'Workout published successfully!',
    ocultar_treino: 'The selected workouts will be hidden from students. Do you want to continue?',
    ocultar_treino_title: 'Hide Workout',
    ocultar_treino_sucesso: 'Workout successfully hidden!',
    enviar_treino: 'Do you want to send a copy of this workout to the program?',
    enviar_treino_title: 'Send Workout',
    enviar_treino_sucesso: 'Workout successfully sent!',
    exportar_pdf_title: 'Export PDF',
    help_video: '<p>If necessary, include an explanatory video of the workout for your customer.</p>' +
                '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Saiba mais</a></p>',
    tag_adicionada: 'Tag added to workout.',
    tags_adicionadas: 'Tags added to workout.',
    tags_atualizadas: 'Tags updated.',
    tags_exibidas: 'The tags for these workouts will be displayed to students.',
    tags_ocultadas: 'The tags for these workouts will be hidden to students.',
    gerar_pdf_completo: 'Generate PDF of the full program',
    programa_sem_treino: 'This program does not contain any workouts. Let\'s create?',
    tp_apagar_treino: 'Delete training',
    tp_clonar_treino: 'Clone training',
    tp_ocultar_selecionados: 'Hide selected',
    tp_exibir_selecionados: 'Show selected',
    tp_exportar_clone: 'Export clone',
    tp_agendar_liberacao: 'Schedule release',
    tp_exportar_pdf: 'Export selected training to PDF',
    tp_tag_id: 'Identification Tag',
    enviar_programa: 'Send to which program?',
    tag_title: 'Identification tags',
    tag_subtitle: 'Select a tag to identify the type of training for the student (optional):',
    tag_list: 'Display tags for the student in the app',
    sem_tag: 'No tag (default)',
    sem_descricao: 'No description',
    selecionados: 'selected:',
    ocultado: 'Hidden',
    programado: 'Scheduled',
    para: 'for',
    liberado: 'Released',
    em: 'in',
    listado: 'Listed',
  },

  // EXERCICIOS
  ExerciciosPage: {
    click_enviar_banner: 'Click here to upload the exercise thumb',
    arraste_banner: 'Drag the exercise thumb here',
    banner_upload_sucesso: 'Thumb Banner sent successfully!',
    atualizado_sucesso: 'Exercise updated successfully!',
    cadastrado_sucesso: 'The Exercise was successfully registered!',
    apagar_exercicio: 'Do you really want to delete this exercise?',
    apagar_exercicio_title: 'Delete Exercise',
    apagar_exercicio_placeholder: 'type DELETE, with capslock on',
    apagar_exercicio_confirmacao: 'DELETE',
    apagar_exercicio_confirmacao_sucesso: 'The exercise has been deleted successfully!',
    reordenar_exercicio_sucesso: 'Exercise successfully reordered!',
    reordenar_exercicios_sucesso: 'Exercises successfully reordered!',
    campo_intervalo: 'The range field accepts only numbers',
    campo_nome_obrigatorio: 'The name field is required',
    serie_cadastro_sucesso: 'The series was successfully registered!',
    serie_atualizado_sucesso: 'Series successfully updated!',
    apagar_serie: 'Do you really want to delete this Series? The exercises will not be deleted, only the grouping box.',
    apagar_serie_title: 'Delete Series',
    apagar_serie_placeholder: 'type DELETE, with capslock on',
    apagar_serie_confirmacao: 'DELETE',
    apagar_serie_confirmacao_sucesso: 'The series has been successfully deleted!',
    atualizar_exercicio_a: 'Do you really want to update the field',
    atualizar_exercicio_b: 'for all exercises?', 
    atualizar_exercicio_title: 'Update Exercises',
    atualizar_exercicio_sucesso: 'The exercises have been updated successfully!',
    ajuda_intervalo:  '<p>In the interval field, put only numbers per second.</p>' +
                      '<p class="red">Do not use quotation marks at the end of numbers.</p>',
    ajuda_video:  '<p>If necessary, include an explanatory video of the training for your customer.</p>' +
                  '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">See more</a></p>',
    apagar_exercicios: 'Do you really want to delete the selected Exercise(s)?',
    apagar_exercicios_title: 'Delete Exercises',
    apagar_exercicios_sucesso: 'The operation was successful!',
    clonar_exercicio: 'Do you really want to clone the selected Exercise(s)?',
    o_limite_de: 'The limit of ',
    limite_exercicios: ' exercises will be achieved with this cloning. Delete some exercise or select fewer exercises if you want to make some clone.',
    clonar_exercicio_title: 'Clone Exercise',
    clonar_exercicio_sucesso: 'Cloning successful!',

    series_repetition: 'Sets x Reps',
    interval: 'Rest',
    bt_agrupar: 'Agroup',
    nenhum_exercicio: 'This workout does not contain any exercises. To add them, click on "Libraries".',
    treinos_selecionados: 'Selected workouts:',
    salvar_serie: 'Save Series',
    selecione_cor: 'Select a color for the Series:',
    deletar_exercicio: 'Delete Exercise',
    sugestao_carga: 'Weight:',
    intervalo: 'Rest:',
    antes_enviar_poster: 'Before uploading a thumbnail, add a TITLE to the Exercise.',
    imagem_miniatura: 'Exercise Thumbnail Image',
    nenhum_video: 'No Videos Uploaded',
    aplicar_todos: 'Apply to all',
    sugestao_carga_opcional: 'Load suggestion (optional)',

    adicionar_exercicio_biblioteca: 'Are you sure you want to add the selected exercises to your workout?',
    cadastro_exercicios: 'Exercise Registration',
    campos_atualizados: 'Fields updated successfully!',
    minha_biblioteca: 'My Library',
    exercicios_listados: 'exercises listed',
    exercicio_listado: 'listed exercise',
    tipo: 'Type',
    metodos: 'Methods',
    musculos: 'Muscles',
    equipamentos: 'Equipment',
    exercicios_selecionados: 'Selected exercises',
    add_ao_treino: 'add to workout',
    limpar_selecao: 'clear selection',
    adicionar_video: 'You can add and organize your videos in My Library.',
    saiba_mais_biblioteca: 'Find out more at Menu > My Library.',
    novo_treino: 'NEW WORKOUT',
    editar_treino: 'EDIT WORKOUT',
  },

  // ALUNOS
  AlunosPage: {
    apagar_aluno_a: 'Do you really want to delete the customer <b class="name">',
    apagar_aluno_b: '</b>? All his data will be deleted!',
    apagar_aluno_title: 'Delete Customer',
    apagar_aluno_msg: 'type DELETE, with capslock on',
    apagar_aluno_placeholder: 'type DELETE, with capslock on',
    apagar_aluno_confirmacao: 'DELETE',
    apagar_aluno_confirmacao_sucesso_a: 'The Customer  (',
    apagar_aluno_confirmacao_sucesso_b: ') was successfully deleted!',
    expiracao: '<img src="assets/imgs/timer.svg" alt="">Mark the access time of the ',
    expiracao_lable: 'Current Expiration:',
    expiracao_msg_a: 'The validity of the customer\'s plan (',
    expiracao_msg_b: ') has been updated successfully!',
    cliente_ativo_bloqueado: ') has been successfully blocked!',
    cliente_ativo_desbloqueado: ') has been activated successfully!',
    cliente_apagar: 'Are you sure you want to delete expired customers?',
    cliente_apagar_title: 'Delete expired customers',
    cliente_apagar_sucesso: 'The accounts were deleted successfully!',
    cliente_desbloquear: 'Are you sure you want to unlock unexpired customers?',
    cliente_desbloquear_title: 'Unlock active customers',
    cliente_desbloquear_sucesso: 'The customers have been successfully unlocked!',
    limite_maximo:  'Sorry, you have reached the maximum number of registrations allowed on this account. ' +
                    'Please contact your system administrator for more information ' +
                    'or to request an upgrade to your access level.',
    limite_clientes: 'Oops! You\'ve reached the account limit on your current subscription. Please upgrade or delete some old customers.',
    cadastro_title: 'Register',
    nenhuma_conta: 'No account selected',
    contas_bloqueadas_sucesso: 'Accounts have been successfully blocked!',
    todas_as_contas: '<p class="danger">ATTENTION!<br> You have selected ALL customers!</p>',
    cinco_contas: '<p class="danger"> ATTENTION!<br> You have selected more than 5 customers!</p>',
    contas_apagar: '<p>Are you sure you want to delete the selected accounts? All customer data and activities will be deleted!</p>',
    contas_apagar_title: 'Delete accounts',
    contas_apagar_placeholder: 'type DELETE, with capslock on',
    contas_apagar_confirmacao: 'DELETE',
    contas_apagar_sucesso: 'The accounts were deleted successfully!',
    contas_apagar_falha: 'The action has been canceled. Please check that you have entered the confirmation text correctly.',
    avalicao_title: 'Assessment',
    precadastro_ativadas_sucesso: 'Customers have been activated successfully!',
    precadastro_nao_selecionadas: 'No customers were selected',
    precadastro_apagar: 'Do you really want to delete these potential customers?',
    precadastro_apagar_title: 'Delete Pre-registration',
    precadastro_apagar_sucesso: 'Pre-registrations have been successfully deleted!',
    precadastro_aceite: 'Are you sure you want to add this pre-registration to your customer list ',
    precadastro_aceite_title: 'Accept new ',
    precadastro_aceite_sucesso: 'Pre-registration was successfully accepted!',
    precadastro_remover: 'Are you sure you want to remove this pre-registration from your waiting list?',
    precadastro_remover_title: 'Reject new ',
    precadastro_remover_sucesso: 'Pre-registration was successfully declined!',
    conta_recuperacao_stcoach: '<p>Account recovery is a feature exclusive to the Pro and Black plans.</p> <p>Do you want to upgrade your plan?</p>',
    conta_recuperacao_mydietpro: '<p>Account recovery is a feature exclusive to the Lite and Gold plans.</p> <p>Do you want to upgrade your plan?</p>',
    conta_recuperacao_title: 'Account Recovery',
    conta_recuperacao_excede: '<p><center>Account recovery exceeds your current plan.</p> <p>Would you like to subscribe to a higher plan?</center></p>',
    conta_recuperacao_excede_2: '<p><center>Account recovery exceeds your current plan.</p> <p>Would you like to subscribe to a higher plan?</center></p>',
    conta_recuperacao_single: 'Are you sure you want to restore these account?',
    conta_recuperacao_multi: 'Are you sure you want to restore those accounts?',
    conta_recuperacao_none: 'No account selected',
    conta_recuperacao_title_2: 'Restore account',
    conta_recuperacao_sucesso: 'Action executed successfully!',
    conta_exclusao: 'Are you sure you want to completely delete this account? After this action all data will be permanently deleted!',
    conta_exclusao_nenhuma: 'No account selected',
    conta_exclusao_confirmacao: 'Are you sure you want to completely delete these accounts? After this action all data will be permanently deleted!',
    conta_exclusao_title: 'Permanently delete',
    conta_exclusao_sucesso: 'Action executed successfully!',
    editar_link_captacao: 'Edit capture link',
    aceitos: 'Accepted',
    lista_espera: 'Waitlist',
    excluidos: 'Excluded',
    opcoes: 'Options',
    reativar_contas: 'Reactivate all disabled accounts',
    selecionados: 'selected:',
    saiba_tudo_sobre: 'Find out all about',
    aceitar_selecionados: 'Accept Selected',
    recusar_selecionados: 'Decline Selected',
    recuperar_selecionados: 'Recover Selected',
    eliminar_selecionados: 'Delete Selected',
    data_inscricao: 'Registration Date',
    data_exclusao: 'Deletion Date',
    lista_espera_vazia: 'There are no accounts on the waiting list.',
    recurso_rest_conta_h: 'The account restore feature is exclusive to the Pro and Black plans.',
    recurso_rest_conta_p: 'Deleted accounts can be recovered within 7 days. After this period, they will be completely deleted from the system and cannot be recovered.',
    recurso_rest_conta_h_diet: 'The account restore feature is exclusive to the Lite and Gold plan.',
    recurso_rest_conta_p_diet: 'Deleted accounts can be recovered within 7 days. After this period, they will be completely deleted from the system and cannot be recovered.',
    contas_selecionadas: 'Selected accounts:',
    dias_restantes: 'Days Remaining',
    lista_exclusao_vazia: 'There are no accounts in the exclusion list.',
    menu_aluno: 'Customer\'s Menu',
  },

  DialogValuationComponent: {
    bioimpedancia: 'Bioimpedance',
    dados_balanca: 'Scale data',
    db_gordura: 'Fat',
    musculo_esqueletico: 'Skeletal Muscle',
    idade_corporal: 'Body Age',
    metabolismo_basal: 'Basal Metabolism',
    gordura_visceral: 'Visceral Fat',
    agua_corporal: 'Body Water',
    massa_muscular: 'Muscle Mass',
    massa_gorda: 'Fat Mass',
    massa_ossea: 'Bone Mass',
    db_anos: 'Years',
    nivel: 'Level',
    pollock_homem: 'Pollock 7-Site Skinfold (men)',
    pollock_mulher: 'Pollock 7-Site Skinfold (women)',
    referencias: 'References',
    tabela_acms: 'Ideal Body Fat % Table, ACMS - Lea & Febiger, 1986',
    tabela_acms_idades: 'Age</div><div>18 to 29</div><div>30 to 39</div><div>40 to 49</div><div>50 to 59</div><div> > 60 ',
    tabela_acms_homens: 'Men</div><div>14%</div><div>16%</div><div>17%</div><div>18%</div><div>21%',
    tabela_acms_mulheres: 'Women</div><div>19%</div><div>21%</div><div>22%</div><div>23%</div><div>26%',
    tabela_imc: 'BMI Table by the World Health Organization',
    tabela_imc_baixo: 'Low</div><div class="value"> < 18,5',
    tabela_imc_baixo_tag: 'Mild obesity</div><div class="value"> 30,0 to 34,9',
    tabela_imc_ideal: 'Ideal</div><div class="value"> 18,5 to 24,9',
    tabela_imc_ideal_tag: 'Moderate obesity</div><div class="value"> 35,0 to 39,9',
    tabela_imc_sobre: 'Overweight</div><div class="value"> 25,0 to 29,9',
    tabela_imc_sobre_tag: 'Morbid obesity</div><div class="value"> > 40',
    deletar_avaliacao: 'Do you really want to delete this assessment?',
    deletar_avaliacao_title: 'Delete assessment',
    deletar_avaliacao_sucesso: ': Data has been deleted successfully!',
    salvar_avaliacao_sucesso: ': Data has been updated successfully!',
    obesidade_morbida: 'Morbid obesity',
    obesidade_mnoderada: 'Moderate obesity',
    obesidade_leve: 'Mild obesity',
    sobrepeso: 'Overweight',
    idealpeso: 'Ideal',
    pesobaixo: 'Low',
    gorduraatual: 'current fat',
    pesogordo: 'fat weight',
    pesomagro: 'thin weight',
    pesoideal: 'ideal weight',
    excesso: 'excess',
    avaliacao_fisica: 'Assessment',
    avaliacao_dia: 'Assessment of the day',
    selecione_nova: 'Select an assessment or add a new one',
    nova_avaliacao: 'New assessment',
    data_avaliacao: 'Date',
    peso: 'Weight',
    altura: 'Height',
    idade: 'Age',
    anos: 'years',
    gorduraideal: 'Ideal fat',
    gordura: 'fat',
    imc: 'BMI',
    nenhuma_avaliacao: 'No Assessments registered. Would you like to register a new one?',
    medidas: 'Measures',
    selecione_protocolo: 'Select protocol',
    dobras_cutaneas: 'Skin folds',
    subescapular: 'Subscapular',
    tricipital: 'Triceps',
    peitoral: 'Pectoral',
    axilarmedia: 'Midaxillary',
    suprailiaca: 'Suprailiac',
    abdominal: 'Abdomen',
    coxa: 'Thigh',
    resultados: 'Results',
    massagorda: 'fat mass',
    massamagra: 'lean mass',
    perimetros_centrais: 'Central Perimeters',
    ombros: 'Shoulders',
    torax: 'Chest',
    cintura: 'Waist',
    quadril: 'Hip',
    perimetros_perifericos: 'Peripheral Perimeters',
    direito: 'Right',
    esquerdo: 'Left',
    antebraco: 'Forearm',
    braco_relaxado: 'Relaxed arm',
    braco_contraido: 'Contracted arm',
    coxa_proximal: 'Proximal thigh',
    coxa_media: 'Middle thigh',
    panturrilha: 'Calf',
    voltar_avaliacoes: 'Back to the assessments list',
    salvar_avaliacao: 'Save assessment',
  },

  FormComponent: {
    click_aqui: 'Click here to upload',
    arraste_aqui: 'Drag the photo here',
    foto_removida_sucesso: 'The photo has been removed successfully!',
    senha_diferente: 'The passwords don\'t match!',
    tel_branco: 'The phone field cannot be empty.',
    conta_atualizada_sucesso: 'Account details have been updated successfully!',
    criado_sucesso: ' created successfully!',
    email_cadastrado: 'E-mail already exists! Please register another e-mail.',
    apagar_conta: 'Do you really want to delete this account? All data will be deleted!',
    apagar_conta_title: 'Delete account',
    apagar_conta_sucesso: 'The account has been deleted successfully!',
    foto_enviada_sucesso: 'The photo was sent successfully!',
    formulario: 'Form',
    limpar_formulario: 'Clear Form',
    nome: 'Name',
    email: 'Email',
    objetivo: 'Goal',
    senha: 'Password (minimum 6 digits)',
    confirme_senha: 'Confirm the Password',
    foto: 'Photo',
    remover_foto: 'Remove photo',
    info_adicional: 'Additional Information',
    info_adicional_exp: 'We can deliver a better experience if you tell us a little more about yourself',
    celular: 'Cellphone',
    ddi: 'DDI',
    telefone: 'Cellphone/Whatsapp',
    ddd_numero: 'Number',
    data_nascimento: 'Date of birth',
    selecione_opcao: 'Select an option',
    altura: 'Height (m)',
    peso: 'Weight (kg)',
    atencao: 'Attention!',
    este_email: 'This email is already in our database.',
    favor_email: 'Please register another email.',
  },
  
  CustomerAnamnesisComponent: {
    queixa_dor_cabeca: 'Headache',
    queixa_dor_pescoco: 'Neck ache',
    queixa_dor_peito: 'Chest pain',
    queixa_dor_ombros: 'Shoulder pain',
    queixa_dor_lombar: 'Lower back pain',
    queixa_falta_ar_esforco: 'Shortness of breath with exertion',
    queixa_falta_ar_repouso: 'Shortness of breath at rest',
    queixa_palpitacoes: 'Heart palpitations',
    queixa_dor_joelhos: 'Knee pain',
    queixa_tontura: 'Dizziness',
    queixa_sem_dores: 'I have no pain',
    doenca_diabetes: 'Diabetes',
    doenca_colesterol: 'Cholesterol',
    doenca_pressao_alta: 'High pressure',
    doenca_nao_tem: 'I do not have anything',
    fav_serie_trad: 'Traditional bodybuilding series',
    fav_serie_circ: 'Circuited series',
    fav_peso_corpo: 'Bodyweight workouts',
    fav_peso_cardio: 'Alternate weights and cardio',
    senha_invalida: 'This password is not valid. Please try again.',
    anamnese_add_sucesso: 'Patient history recorded successfully!',
    anamnese_apagar: 'Do you want to delete this Patient history?',
    anamnese_apagar_title: 'Delete Patient History',
    anamnese_apagar_sucesso: 'Patient history successfully deleted!',
    questionario: 'Patient History: answer the health questionnaire',
    digite_senha: 'Enter your password to validate access to the health questionnaire.',
    precisa_senha: 'You need to enter your password',
    validar: 'Validate',
    anamnese: 'Patient History',
    avaliacao_saude: 'Patient History - Health Questionnaire.',
    responda: 'Please answer the questionnaire as completely as possible.',
    envie_link: 'Send the link for the customer to access the patient history.',
    anamnese_vazia: 'The student has not yet completed any patient history',
    voltar_lista: 'Back to list',
    queixas_atuais: 'Current complaints',
    outros: 'Others',
    doencas_pre: 'Pre-existing diseases',
    antecedentes_pessoais: 'Personal History',
    cirurgias: 'Surgeries?',
    traumas: 'Trauma?',
    antecedentes_familiares: 'family History',
    colesterol: 'Cholesterol?',
    diabetes: 'Diabetes?',
    habitos: 'Lifestyle Habits',
    etilismo: 'Alcoholism?',
    tabagismo: 'Smoking?',
    anticoncepcionais: 'Contraceptives?',
    historico_treino: 'Training History',
    quanto_tempo_treino: 'How long have you been training?',
    quantas_vezes_treina: 'How many times a week can you train?',
    periodo_manha: 'In the morning',
    periodo_tarde: 'In the afternoon',
    periodo_noite: 'At night',
    periodo_possivel: 'Is it possible for you to train twice in a single day? If so, which days of the week would be possible? How much time per day do you have available to train?',
    treino_gosta: 'What types of workouts do you like the most?',
    sobre_treino_anterior: 'Anything you want to tell me about your previous training? Describe the details:',
    data_inicio: 'Select the date you want to start training',
    complemento_anamnese: 'Describe something important that can complement this health questionnaire.',
    apagar_anamnese: 'Delete Patient History',
    obrigado: 'Thank you for completing this health questionnaire.',
  },

  CustomerMenusComponent: {
    lista_prescricoes_atualizada: 'The Prescriptions list has been updated.',
    ajuda_enviar_programas: '<p>First, choose the customer you want to edit. This will make the Program List and the Form tab available.</p>'
                          + '<p>Then select the programs you want to send to the customer by clicking on the “hidden/selected” key.</p>'
                          + '<p>The program will be sent immediately.</p>'
                          + '<p>Select as many programs as you need.</p>'
                          + '<p>If you want to view the Waiting List again, click the BACK button on the Form tab.</p>',
    prescricoes: 'Prescriptions',
    prescricoes_listadas: ' listed prescriptions',
    prescricao_listada: ' listed prescription',
    disponiveis: 'available',
    selecionado: 'Selected',
    oculto: 'Hidden',
  },
  
  CustomerProgramsComponent: {
    lista_programas_atualizada: 'The customer\'s program list has been updated.',
    ajuda_enviar_programas: '<p>First of all, choose the customer you want to edit. This will make the Program List and the Form tab available.</p>'
                          + '<p>Then select the programs you want to send to the customer by clicking on the “hidden/selected” switch.</p>'
                          + '<p>The program will be sent immediately.</p>'
                          + '<p>Select as many programs as you need.</p>'
                          + '<p>If you want to view the Waiting List again, click the BACK button on the Form tab.</p>',
    programas: 'Programs',
    programas_listados: ' listed programs',
    programa_listado: ' listed program',
    disponiveis: 'available',
    selecionado: 'Selected',
    oculto: 'Hidden',
    agenda: 'Schedule',
  },
  
  CustomerHistoryComponent: {
    historico: 'History',
    historico_vazio: 'Activity history is empty',
    concluido: 'Completed',
    programa: 'Program',
  },
  
  CustomerNotesComponent: {
    notas: 'Notes',
    notas_subtitulo: 'These are the customer\'s latest notes within the app.',
    nenhuma_anotacao: 'No notes were made.',
  },
  
  CustomerFilesComponent: {
    arquivos: 'Archives',
    onde_visualizar_arquivo: 'Where will the customer view this file?',
    todos_arquivos: 'All files.',
    remover_arquivo: 'Do you want to remove this file from the customer?',
    remover_arquivo_titulo: 'Remove file',
    lista_arquivo_sucesso: 'The Customer Files list has been updated.',
    ajuda_arquivos: '<p>These are all the files uploaded to your account.</p>'
                  + '<p>Select the ones you want to send to this customer.</p>'
                  + '<p>To upload more documents, rename or delete them, access the "Files" option in the menu.</p>',
    selecionado: 'Selected',
    oculto: 'Hidden',              
  },

  NutricaoComponent: {
    alimento_alterado_sucesso: 'The food has been changed successfully!',
    alimento_cadastrado_sucesso: 'The food was successfully registered!',
    alimento_clonado_sucesso: 'The food has been successfully cloned!',
    alimento_apagar: 'Are you sure you want to delete this food?',
    alimento_apagar_title: 'Delete Food',
    alimento_apagar_sucesso: 'The food was successfully deleted!',
    ajuda: '<p>First, choose the customer you want to edit. This will make the Program List and the Form tab available.</p>'
         + '<p>Then select the programs you want to send to the customer by clicking on the “hidden/selected” key.</p>'
         + '<p>The program will be sent immediately.</p>'
         + '<p>Select as many programs as you need.</p>'
         + '<p>If you want to view the Waiting List again, click the BACK button on the Form tab.</p>',
    formulario: 'FORM',
    formulario_limpar: 'CLEAR FORM',
    novo_alimento: 'New Food',
    cadastre_alimentos: 'Register food with this form.',
    editar_alimento: 'Edit Food',
    nome: 'Name',
    unidades: 'Units',
    gramas: 'Grams',
    mililitros: 'Milliliters',
    porcao: 'Portion',
    quantidade: 'Amount',
    energia: 'Energy',
    carboidratos: 'Carbohydrates',
    acucares: 'Sugars',
    proteinas: 'Proteins',
    gorduras: 'Fats',
    gord_saturadas: 'Saturated Fats',
    gord_monoinsaturadas: 'Monounsaturated Fats',
    gord_poliinstaturadas: 'Polyunsaturated Fats',
    colesterol: 'Cholesterol',
    fibras: 'Fibers',
    sodio: 'Sodium',
    potassio: 'Potassium',
    alimentos: 'FOOD',
    alimentos_listados: ' foods listed',
    alimento_listado: ' food listed',
    disponiveis: 'available',
    adicione_alimento: 'Add a food',
  },
  
  MenusComponent: {
    legend_title: 'Calculation',
    extra_suplementos: 'Sample text:\n\n- Isolate whey protein\n- Creatine monohydrate\n- Multivitamin & Mineral\n- Ômega 3\n- UC II',
    extra_manipulados: 'Sample text:\n\nFormula 1:\n- Metilfolato (1mg)\n- Pridoxal 5 Fosfato (25mg)\n\nFórmula 2:- Curumina (300mg)\n- Piperine (3mg)',
    extra_compras: 'Sample text:\n\n- Brown rice\n- Potatoes\n- Oats\n- Chicken breast\n- Tilapia\n- Banana',
    menu_atualizado_sucesso: 'The prescription has been updated successfully!',
    menu_cadastrado_sucesso: 'The prescription was registered successfully!',
    menu_clonado_sucesso: 'The prescription has been successfully cloned!',
    marca_dagua_mydietpro: 'MYDIETPRO IN FREE MODE',
    marca_dagua_funcaex: 'EXCLUSIVE FEATURE FROM THE PRO PLAN ONWARDS',
    marca_dagua_stcoach: 'STCOACH IN FREE MODE',
    proteinas: 'Proteins',
    lipidios: 'Lipids',
    carbo: 'Carbohydrates',
    texto_livre: '[Free text up to 10,000 characters]',
    nova_refeicao: 'New Meal',
    refeicoes_geradas: 'Meals created successfully!',
    nova_refeicao_sucesso: 'New meal created successfully!',
    refeicao_atualizada_sucesso: 'Meal updated successfully!',
    clonar_refeicao: 'Do you want to clone this meal?',
    clonar_refeicao_title: 'Clone meal',
    clonar_refeicao_sucesso: 'Meal cloned successfully!',
    remover_refeicao: 'Do you want to remove this meal?',
    remover_refeicao_title: 'Delete meal',
    remover_refeicao_sucesso: 'Meal successfully removed from the system!',
    reordenar_refeicao: 'Meals successfully reordered!',
    lista_atualizada: 'The customer\'s prescriptions list has been updated.',
    lista_atualizada_pacientes: 'The prescription list has been updated.',
    lista_arquivos_atualizada: 'The Customer Files list has been updated.',
    modal_limite: 'Sorry, you have reached the maximum number of registrations allowed on this account. '
                + 'Please contact your system administrator for more information '
                + 'or to request an upgrade to your access level.',
    modal_limite_menus: 'Oops! You\'ve reached the prescription limit on your current account. Please upgrade or delete some old prescriptions.',
    menus_title: 'Prescriptions',
    nenhuma_prescricao: 'No prescription was selected',
    um_clone_vez: 'Please clone only one prescription at a time.',
    um_pdf_vez: 'Please generate PDF for only one prescription at a time.',
    gerando_pdf: 'Generating PDF, please wait...',
    apagar_prescricoes: 'Do you want to delete the selected prescriptions?',
    apagar_prescricoes_title: 'Delete Prescriptions',
    escreva_deletar: 'write DELETE, in capital letters',
    escreva_deletar_input: 'DELETE',
    apagar_prescricoes_sucesso: 'Files have been deleted successfully!',
    prescricoes: 'Prescriptions',
    subtitulo_nutri: 'Create complete nutritional plans and send them to your customers.',
    subtitulo_prof: 'Create complete nutritional plans and send them to your customers.',
    funcao_desktop: 'Function available for desktop.',
    funcao_desktop_sub: 'Currently, this feature is only available for desktop access. We are actively working to adapt the screens for the mobile version.',
    nova_prescricao: '+ New',
    gerar_pdf: 'Export PDF',
    clonar: 'Clone',
    alimento: 'Food',
    alimentos: 'Foods',
    textos_livres: 'Free texts',
    ultima_atualizacao: 'Last update was made on',
    tipo: 'Type',
    kcal: 'Kcal',
    prot: 'Prot.',
    carb: 'Carb.',
    gord: 'Fats.',
    selecione_pacientes: 'Select customers for this prescription',
    selecione_alunos: 'Select customers for this prescription',
    enviar_todos: 'Send to all',   
    selecionado: 'Selected',
    ocultado: 'Hidden',
    sem_menus: 'There are no registered nutritional plans.',
    voltar_tela: 'Return to previous screen',
    atualizar_prescricao: 'Update Prescription',
    nome_obrigatorio: 'Give this prescription a name (required)',
    continuar: 'Continue',
    como_deseja: 'How do you want to prescribe?',
    menu_1_desc: 'The prescription is made by choosing foods from our database. This method takes into account macro and micronutrient calculations.',
    menu_2_desc: 'The diet prescription is made through free text, without considering calculations. However, it is possible to enter the values ​​manually.',
    menu_1: 'Create diet meals with foods from the database. Edit whatever you want.',
    menu_2: 'Create meals with free text. Edit whatever you want.',
    gerar_ref_inicial: 'Generate initial meal list',
    alimento_adicionar: 'Add a food from the database',
    alimento_texto_livre: 'Add free text',
    alimento_duplicar: 'Double this meal',
    alimento_apagar: 'Delete this meal',
    hora: 'Hour',
    nome_menu: 'Meal name (Default)',
    nome_menu_en: 'Meal name (English)',
    nome_menu_es: 'Meal name (Spanish)',
    qtd: 'amt',
    unidade: 'unit',
    lipid: 'Lipid.',
    calorias: 'Calories',
    porcao: ' Portion',
    porcoes: ' Portions',
    total_macro: 'Total macronutrients',
    sem_alimentos: 'Meal without food',
    sem_descricao: 'Meal without description',
    nutrientes: 'Nutrients',
    acucares: 'Sugars',
    fibras: 'Fibers',
    sodio: 'Sodium',
    potassio: 'Potassium',
    colesterol: 'Cholesterol',
    gd_sat: 'Saturated Fat',
    gd_monosat: 'Monounsaturated Fat',
    gd_polisat: 'Polyunsaturated Fat',
    gd_total: 'Total Fat',
    atualizar_nutrientes: 'Update nutrients',
    suplementos: 'Supplements',
    manipulados: 'Compounded medicines',
    lista_compras: 'Market list',
    quantidade: 'Amount',
    relatorio_nutrientes: 'Nutrient Report',
    refeicao: 'Meal',
    total: 'Total',
    atualizar_crn_title: 'Action required!',
    atualizar_crn: 'To use this feature, you must fill in the nutrition professional\'s details.',
    atualizar_crn_bt_ok: 'Go to registration',
  },

  ArquivosComponent: {
    carregue_arquivo: 'UPLOAD FILE',
    arquivo_maximo: 'The file must be up to ',
    remover_arquivo: 'Do you want to remove this file?',
    remover_arquivo_titulo: 'Delete file',
    remover_arquivo_sucesso: 'File successfully removed from the system!',
    renomear_arquivo: 'Rename file',
    renomear_arquivo_sucesso: 'File updated successfully!',
    lista_alunos_atualizada: 'The files list has been updated.',
    ajuda_todos_arquivos: '<p>All files uploaded to your account.</p>'
                        + '<p>Select the files you want to send to the customer.</p>'
                        + '<p>To upload more documents, rename or delete them, access the "Files" option in the menu.</p>',
    arquivo_grande: 'The file is larger than the available storage space!',
    sem_espaco: 'Insufficient storage space!',
    arquivo_envio_sucesso: 'Your file has been uploaded successfully!',
    nenhum_selecionado: 'No files selected',
    apagar_arquivos: 'Do you really want to delete the selected files?',
    apagar_arquivos_titulo: 'Delete Files',
    apagar_arquivos_placeholder: 'write DELETE, in capital letters', 
    apagar_arquivos_confirmacao: 'DELETE',
    apagar_arquivos_sucesso: 'Files have been deleted successfully!',
    ajuda_legenda: 'Where will the customer see this file?',
    recurso_exclusivo: 'Feature available from the Standard plan.',
    armazenamento_maximo: 'Maximum storage',
    total_usado: 'Total Used',
    precisa_mais: 'Need more storage space?',
    faca_upgrade: 'upgrade your plan!',
    lista: 'List',
    lista_vazia: 'Your files list is empty.',
    enviado: 'Sent',
    atualizado: 'Updated',
    alunos_selecionados: 'Selected customers',
    selecione_alunos: 'Select customers for this document',
    enviar_todos: 'Send to all',
    selecionado: 'Selected',
    ocultado: 'Hidden',
    nenhum_arquivo: 'No files registered.',
  },
  
  BibliotecaComponent: {
    exercicio_atualizado_sucesso: 'Exercise updated successfully!',
    exercicio_cadastrado_sucesso: 'The exercise was successfully registered!',
    apagar_exercicio: 'Do you really want to delete this Exercise? a',
    apagar_exercicio_title: 'Delete Exercise b',
    apagar_exercicio_sucesso: 'The Exercise has been successfully deleted!',
    nenhum_selecionado: 'No exercise selected',
    apagar_exercicio_certeza: 'Do you really want to delete the selected exercises?',
    apagar_exercicio_certeza_title: 'Delete',
    apagar_exercicio_certeza_placeholder: 'write DELETE, in capital letters',
    apagar_exercicio_certeza_confirmacao: 'DELETE',
    apagar_exercicio_certeza_sucesso: 'The exercise was deleted successfully!',
    apagar_exercicios_certeza_sucesso: 'The exercises were deleted successfully!',
    banner_enviado_sucesso: 'Banner sent successfully. Don\'t forget to save the Exercise!',
    biblioteca_ajuda: '<h4>Add up to 300 videos.</h4>'
                    + '<p>In this section you can organize up to 300 exercise videos to use in your training montages.</p>'
  
                    + '<h4>Pro Feature</h4>'
                    + '<p>Private videos can only be used by subscribers on the Pro plan and above.</p>'
  
                    + '<h4>Videos</h4>'
                    + '<p>"My Library" must be created using YouTube links or Vimeo codes. <a href="https://www.stcoach.app/tutoriais-videos" target="_blank">Click here</a> to find out.</p>'
  
                    + '<h4>Images</h4>'
                    + '<p>Exercise thumbnails are optional. If you add them, follow these recommendations:</p>'
                    + '<ul><li>JPG format</li>'
                    + '<li>Dimension 300x300px</li></ul>',
    exclusivo_pro_plus: 'This feature is exclusive to Pro subscribers and above. Want to upgrade your plan?',
    exclusivo_pro_plus_title: 'Exclusive Feature',
    minha_biblioteca: 'My Library',
    biblioteca_vazia: 'No exercises registered in my library.',
    biblioteca_procurando: 'Searching for library exercises...',
    novo_exercicio: 'New exercise',
    editar_exercicio: 'Edit exercise',
    antes_enviar_banner: 'Before uploading a thumbnail, add a TITLE to the Exercise.',
    enviar_mini: 'Click here to upload the thumbnail',
  },

  ProfessoresComponent: {
    clique_envie_foto: 'Upload photo',
    arraste_foto: 'Drag your photo here',
    clique_envie_logo: 'Upload logo',
    arraste_logo: 'Drag your logo here',
    dados_atualizados_sucesso: 'Your data has been updated successfully!',
    conta_ativada_sucesso: 'Your account has been activated successfully!',
    cancelamento_plano: 'Plan cancellation.',
    cancelamento_plano_teacher: ''
                              + '<center><p><b>When you cancel your plan, your account will switch to free mode.</b></p></center>'
                              + '<center><p><b>You will lose access to the workouts you created and your customers will lose access to the app.</b></p></center>',
    cancelamento_plano_nutri: ''
                            + '<center><p><b>When you cancel your subscription, your account will immediately become free.</b></p></center>'
                            + '<center><p><b>You will lose access to diets that exceed the free plan.</b></p></center>',
    cancel_plano_bt_ok: 'Keep the subscription plan',
    cancel_plano_bt_cancel: 'Confirm cancellation',
    ativar_conta: '<p>For security reasons, we need to confirm that your account has been activated before proceeding. This activation is automatic and no additional action is required.</p> <p>Deseja ativar sua conta?</p>',
    ativar_conta_title: 'Activate account',
    plano_atual: ''
              + '<center><p><b>This is your current plan.</b></p></center>'
              + '<center><p><b>When you cancel your plan, your account will switch to free mode.</b></p></center>'
              + '<center><p><b>You will lose access to the workouts you created and your customers will lose access to the app.</b></p></center>',
    plano_atual_bt_ok: 'Keep the subscription plan',
    plano_atual_bt_cancel: 'Confirm cancellation',
    plano_superior: 'Your current plan is higher than the one you selected. Do you want to downgrade to this plan? You will have fewer features available and all extra customers and programs will be disabled.',
    plano_superior_title: 'Subscribe to new plan',
    plano_nao_selecionado: 'Subscription plan not selected!',
    cc_branco: 'Credit card number not filled in!',
    cvc_branco: 'Credit card security code not filled in!',
    expira_mes_branco: 'The credit card expiration month field was not filled in!',
    expira_ano_branco: 'The credit card expiration year field was not filled in!',
    erro_reload: 'An error has occurred and we will reload the page.',
    verifica_pagamento: 'Payment verification has been completed and your plan has been updated',
    aluno_desativado: 'The Customer has been successfully deactivated!',
    aluno_ativado: 'The Customer has been successfully activated!',
    aluno_bloqueado: 'The Customer has been successfully blocked!',
    ajuda_assinatura: ''
                    + '<h3>Signature</h3>'
                    + '<p>- Monthly recurring subscription system (automatic renewal).</p>'
                    + '<p>- Payment by credit card only.</p>'
                    + '<p>- To change the plan, click on the desired one and subscribe.</p>'
                    + '<br/>'
                    + '<h3>Doubts</h3>'
                    + '<p>1. How do I change plans?</p>'
                    + '<p>Click on the desired plan and subscribe again. The old subscription will be automatically canceled and the new one will come into effect. Just try to upgrade within a few days of the automatic renewal, as the amounts are not automatically compensated by the system.</p>'
                    + '<p>2. If I cancel, will I lose my work and my customers?</p>'
                    + '<p>No. If you cancel your subscription or your credit card does not authorize the renewal, the system will block access to students and programs. In the app, your students will lose access to their login.</p>'
                    + '<p>All customers registrations, waiting lists, as well as created programs will remain in the system until the subscription is reactivated.</p>',
    avatar_enviado_sucesso: 'Your avatar has been submitted successfully!',
    logo_enviado_sucesso: 'Your logo has been submitted successfully!',
    foto_removida_sucesso: 'Your photo has been successfully removed!',
    perfil: 'Profile',
    subtitulo: 'Choose the logo that will be displayed in the app.',
    remover_foto: 'Remove photo',
    minha_logo: 'My Logo',
    minha_logo_sub: 'Choose the logo that will be displayed in the system and in the customer app.',
    exclusividade: 'Exclusive',
    logo_pdf: 'Logo for PDF',
    logo_pdf_sub: 'Choose the logo that will be displayed in the PDF header (light background).',
    exclusivo_pro_black: 'Exclusive for plans <span>PRO and Black</span>',
    exclusivo_gold: 'Exclusive <span>GOLD</span>',
    dados: 'Data',
    info_basica: 'Your basic information.',
    ddd_numero: 'Cellphone number',
    selecione_estado: 'Select a State',
    cidade: 'City',
    selecione_opcao: 'Select an option',
    cref: 'Applicable only to professionals in Brazil. You can fill in any number.',
    crn: 'Applicable only to professionals in Brazil. You can fill in any number.',
    cpf: 'Applicable only to professionals in Brazil. You can fill in any number.',
    crefito: 'Applicable only to professionals in Brazil. You can fill in any number.',
    nutriname: 'Requirement for Brazilians only.',
    cpf_not_valid: 'Requirement for Brazilians only.',
    cpf_empty: 'Requirement for Brazilians only.',
    cref_not_empty: 'Fill in your date of birth here',
    crefito_not_empty: 'Requirement for Brazilians only.',
    crn_not_empty: 'Requirement for Brazilians only.',
    nutriname_not_empty: 'Requirement for Brazilians only.',
    sou_estrangeiro: 'I am a foreigner and I declare that I am qualified to practice the profession in accordance with the regulations of my country.',   
    nutriname_igual_nome: 'Requirement for Brazilians only.',
    atividade: 'Job',
    edfisica: 'Fitness Trainer',
    fisio: 'Physiotherapist',
    nutri: 'Nutritionist',
    estudante: 'Student',
    senha: 'Password (minimum 6 digits)',
    senha_sub: 'Just change the fields if you want to change your password.',
    confirme_senha: 'Confirm Password',
    apagar_conta: 'Delete Account',
    planos_assinatura: 'Subscription Plans',
    planos_sub: 'Choose the plan that best fits your profile',
    mes: 'month',
    p_gratis: 'Free',
    p_gratis_alunos: '1 Customer',
    p_gratis_programas: '2 Programs',
    p_gratis_capas: 'Basic Posters',
    p_basic: 'Basic',
    p_standard: 'Standard',
    p_pro: 'Pro',
    p_black: 'Black',
    p_lite: 'Lite',
    p_gold: 'Gold',
    p_alunos: 'Customers',
    p_programas: 'Programs',
    p_capas: 'Basic Posters',
    p_capas_e: 'Special Posters',
    p_arquivos: 'for files',
    p_avaliacao: 'Physical Assessment',
    p_sualogo: 'Your logo in the app',
    p_nutri: 'Nutrition',
    p_mylib: 'My Library',
    p_bkp: 'Security Backup',
    p_pdf: 'PDF workout converter ',
    p_pacientes: 'Customers',
    p_prescricoes: 'Prescriptions',
    p_link: 'Registration link',
    p_pacientes_ili: 'Unlimited patients',
    p_prescricoes_ili: 'Unlimited prescriptions',
    p_gratuito: 'Free Plan',
    capas: '*Plans that have access to special posters also have access to basic posters.',
    meu_plano_atual: 'My current plan',
    gerenciar: 'Manage subscription',
    plano_prepago: 'Prepaid plan',
    expiracao: 'Expiration of current plan',
    renovacao: 'This type of plan requires renewal every month. It does not renew automatically.',
    total_alunos: 'Total number of registered customers',
    ativos: 'Active',
    inativos: 'Inactive',
    total_programas: 'Total Programs',
    arquivos: 'Files',
    total_arquivos: 'Total Files',
    total_pacientes: 'Total customers',
    total_prescricoes: 'Total Prescriptions',
    assinatura_recorrente: 'Recurring subscription',
    renovacao_automatica: 'With automatic renewal every 30 days',
    cc_nome: 'Credit Card Name',
    cc_numero: 'Credit Card Number',
    cc_cvv: 'CVV',
    cc_data_exp: 'Expires',
    cc_mes: 'Mo',
    cc_ano: 'Year',
    cc_doisultimos: '(last two numbers)',
    limpar_campos: 'Clear Fields',
    assinar: 'Subscribe',
    pagamento_pix: 'Prepaid plans',
    sem_renovacao: 'For Brazilian payment method only. No automatic renewal',
    pelo_computador: 'We recommend that this payment method be made via computer.',
    pagar: 'Pay',
    gerando_link: 'Generating payment link...',
    aguarde: 'Please wait a few moments',
    link_sucesso: 'Link generated successfully',
    pag_instrucoes: 'Click the button below and follow the payment instructions for pix, boleto or credit card. This payment method requires a Brazilian CPF.',
    pag_demora: 'Payment made by Pix or credit card takes 2 minutes to release the functions.',
    pag_boleto_demora: 'Payment made by "BOLETO" can take up to 72 hours to be cleared and processed.',
    pag_aguarde: 'Please wait for the periods before opening a claim with us.',
    link_abrir: 'Open Payment Link',
    cancelar_voltar: 'Cancel and return',
    historico: 'History',
    desc_hist_stcoach: 'Here you can see your latest payments.',
    desc_hist_mydiet: 'Here you can see your latest payments.',
    verificando: 'Checking your purchases...',
    expirado: 'Expired',
    processando: 'Processing',
    pago: 'Paid',
    minha_conta: 'My Account',
    aplicativos: 'Apps',
    criado_em: 'Created on',
    ultima_atual: 'Last update',
    avisos_legais: 'Legal Notices',
    termos_uso: 'Terms of Use',
    politiva_priv: 'Privacy Policy',
    politica_cancel: 'Cancellation Policy',
    app_professor: 'Teacher\'s App',
    app_prof_sub: 'Download the exclusive app to receive notifications.',
    app_aluno: 'Student\'s App',
    app_aluno_sub: 'Download the student\'s app to see the training flow.',
    copie_envie: 'Quickly copy and send to the students',
  },

  DialogI18nComponent: {
    campo_vazio: 'The translation field is empty!',
    traduza_texto: 'Translate the text',
    em_ingles: 'English',
    em_espanhol: 'Espanish',
    traduzir: 'Translate',
    add_traducoes: 'Add translations',
  },

  CustomerWaitlineComponent: {
    link_transferencia: 'The link has been copied',
    copia_negada: 'Your system does not allow automated copying. Please copy and paste manually.',
    alterar_sauda: 'Are you sure you want to change your waitlist welcome message?',
    alterar_sauda_title: 'Change welcome message',
    alterar_sauda_sucesso: 'The message has been updated successfully!',
    saudacao_padrao: ''
                    + '<p>Welcome phrase after the customer opens the pre-registration link.</p>'
                    + '<p>Use a friendly message</p>',
    saudacao_ajuda: ''
                    + '<p>To get potential customers on the list, share the link above on your website and social media.</p>'
                    + '<p>Pre-registered customers will not be added as active customers to your plan unless they are accepted.</p>'
                    + '<p>Try accepting potential customers for a free trial and increase your chances of conversion.</p>',
    lista_espera: 'Waiting List',
    link_captacao: 'Pre-registration link',
    saudacao: 'Welcome phrase',
    concordo: 'By registering, I declare that I have read and accept',
    privacidade: 'the privacy policy',
    atencao: 'Attention!',
    este_email: 'This email is already in our database.',
    favor_email: 'Please register another email.',
  },

  DashboardFeedbacksComponent: {
    feedbacks_title: 'Customer feedback',
    feedback_title: 'Feedback',
    titulo_programa: 'Program',
    titulo_treino: 'Workout',
    aluno: 'Customer',
    nome_programa: 'Program Name',
    nome_treino: 'Workout Name',
    feedback_data: 'Date',
    feedback: 'Feedback',
    nenhum_feedback: 'No feedback sent',
    nenhum_feedback_load: 'No feedback loaded',
    ver: 'See',
    ver_7: 'See 7 days',
    ver_15: 'See 15 days',
    ver_30: 'See 30 days',
    ver_todos: 'see all',
  } 
};