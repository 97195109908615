import { Component, EventEmitter, OnChanges, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormControl, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatMenuTrigger } from '@angular/material/menu';

import { ToastrService } from 'ngx-toastr';

import { DialogComponent } from '../../ui/dialog/dialog';
import { DialogLightComponent } from '../../ui/dialog-light/dialog-light';
import { DialogSelectTrainingBannerComponent } from '../../ui/dialog-select-training-banner/dialog-select-training-banner';
import { DialogSelectDateComponent } from '../../ui/dialog-select-date/dialog-select-date';
import { DialogExportPDFComponent } from '../../ui/dialog-export-pdf/dialog-export-pdf';

import { TrainingProvider } from '../../providers/training/training';
import { ProgramProvider } from '../../providers/program/program';
import { GroupsMuscleProvider } from '../../providers/groups-muscle/groups-muscle';
import { GoalProvider } from '../../providers/goal/goal';
import { ApiProvider } from '../../providers/api/api';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { I18nProvider } from '../../providers/i18n/i18n';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-block-trainings',
  templateUrl: 'trainings.html',
})
export class TrainingsComponent implements OnInit, OnChanges {
  STRINGS: any;
  PAGE: any;

  @Output() setTrainingId = new EventEmitter();
  @Output() setTraining = new EventEmitter();
  @Output() finishFastBuild = new EventEmitter();
  @Input() program_id: any;
  @Input() fast_program_id: number = 0;
  @Input() ismobile: boolean = false;
  @Input() istablet: boolean = false;
  @Input() reset: boolean = false;
  // @ViewChild('dzTrainingCover', {static: false}) dzTrainingCover?: DropzoneComponent;
  @ViewChild('selMuscles') selMuscles: MatSelect;
  @ViewChild('checkAll') checkAll: HTMLFormElement;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;

  protected limit: number = 80;
  protected headers: any;
  protected API = (environment.API_ENV_URLS).replace('/api', '/');
  noScroll: boolean = false;

  user: any = {};
  account: any = {
    purchase: {
      supertreinos_plan_id: '0',
    },
    students: {
      total: <number> 0,
      max: <number> 0,
      inactives: <number> 0,
    },
    programs: {
      total: <number> 0,
      max: <number> 0,
    }
  }
  training: any = {
    id: <number> 0,
    cover_path: '',
    name: <string> null,
    subtitle: <string> null,
    video_url: <string> '',
    description: <string> null,
    week_cycle: <number> 1,
    weeks: <string> null,
    days_per_week: <string> null,
    minutes_per_day: <string> null,
    program_id: <number> 0,
    sort: <number> 0,
    pay: <string> null,
    published: <number> 1,
    premium: <number> 0,
    goal: <number> 0,
    points: <number> 2,
    translations: <string> '',
  };
  program: any = {
    id: 0,
    name: null,
    description: null,
    show_tag: 0,
    goal: null,
    difficulty_level: null,
    minutes_per_day: null,
    days_per_week: null,
    weeks: null,
  }   

  trainings: any = [];
  selected_training = 0;
  selected_trainings = [];
  total_trainings = 0;
  total_selected = 0;
  sanitized_video_url: any;
  banner_size: string = '';
  allComplete: boolean = false;
  checkAllIndeterminate: boolean = false;

  goals: any = [];

  loading: boolean;
  cover_url_forceupdate: boolean = false;

  groups_muscle: any;

  cropCheckInterval: any;
  detailsClass: string = 'minimize';

  trainingTags: any;
  selectedTag: number = 1;

  // form validations
  trainingName = new FormControl('', [Validators.required]);
  trainingVideoUrl = new FormControl();

  programs: any;
  searchProgram: any = {
    name: <string> '',
  };
  maxItens: number = 3;
  totalItens = { count: 0 };
  page2: number = 0;
  totalPages: number = 0;
  reload: boolean = false;

  public config: DropzoneConfigInterface = {
    paramName: 'cover_img',
    thumbnailMethod: 'contain',
    thumbnailHeight: null,
    thumbnailWidth: null,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
    transformFile: (file, done) => {
      this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
      this.helpers.cropImage(file, done, 'dropzone-training-cover', 680, 360);
    }
    // autoProcessQueue: false,
  };

  constructor(
    public toastr: ToastrService,
    public trainingProvider: TrainingProvider,
    public programProvider: ProgramProvider,
    private groupsmuscleProv: GroupsMuscleProvider,
    public goalProvider: GoalProvider,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('TreinosPage');
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  getRequiredMessage(el: any) {
    if (el.hasError('required')) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.hasError('incorrect')) {
      return this.STRINGS.campo_invalido;
    }
  }

  ngOnInit() {
    // console.log('ngOnInit');
    this.loading = false;
    this.getTrainings();
    this.config.dictDefaultMessage = ((this.ismobile || this.istablet)
        ? this.PAGE.click_enviar_banner
        : this.PAGE.arraste_banner
    )

    this.programs = this.helpers.getLocallyStored('programs');
    this.trainingTags = this.helpers.getLocallyStored('trainingTags');

    if (window.innerHeight >= 700) this.maxItens = 5;
  }

  ngOnChanges() {
    // console.log('ngOnChanges');
    if (this.reset === true) {
      setTimeout(() => {
        this.goBack(false);
        return true;
      },50);
    } else {
      if (this.loading === false) {
        this.selected_trainings = [];
        this.total_selected =  0;
        this.trainings = [];
        this.getTrainings();
      }
      if (this.program_id === 0) {
        this.setTrainingId.emit(0);
        this.setTraining.emit(0);
        this.selected_training = 0;
      }
    }
    
    this.programs = this.helpers.getLocallyStored('programs');

    if (this.program_id > 0) {
      this.programProvider.program(this.program_id).then(
        (_res: any) => {
          this.program = {
            id: this.program_id,
            name: _res.program.name,
            description: _res.program.description,
            goal: _res.program.goals[0].name,
            show_tag: _res.program.show_tag,
            difficulty_level: _res.program.difficulty_level,
            minutes_per_day: _res.program.minutes_per_day,
            days_per_week: _res.program.days_per_week,
            weeks: _res.program.weeks,
          }        
        },
        (err: any) => {
          console.log(err);
          this.loading = false;
        }
      );
    }

    this.checkFastProgramId(this.fast_program_id);
    
    if (window.innerHeight >= 700) this.maxItens = 5;
  }

  checkFastProgramId(id: number = 0) {
    if (id > 0) {
      this.reset = false;

      // add new fast training
      let training = {
        name: "Treino 1",
        subtitle: "",
        video_url: "",
        description: "",
        week_cycle: 0,
        weeks: "",
        days_per_week: "",
        minutes_per_day: "",
        program_id: id,
        sort: 0,
        pay: 0,
        published: 1,
        premium: 0,
        goal: 0,
        points: 2,
        translations: "",
        cover_url: "https://www.api.homolog.supertreinosapp.com/img/TREINO-BANNER-PADRAO.jpg",
        cover_path: true,  
      }

      this.loading = true;
      this.trainingProvider.postTraining(training).then(
        (res: any) => {
          this.training = res.training;
          this.loading = false;
          this.finishFastBuild.emit(this.training.id);
          this.selectTraining(this.training.id);
        },
        (err: any) => {
          this.loading = false;
          console.log(err);
        }
      );    

    }

  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.trainings, event.previousIndex, event.currentIndex);
    this.trainingReorder(this.trainings);
  }

  mouseScroll(scroll: boolean = true) {
    this.noScroll = !scroll;
  }

  goBack(reload: boolean = true) {
    this.training = this.helpers.resetObject(this.training);
    this.setTrainingId.emit(0);
    this.setTraining.emit(0);
    this.selected_training = 0;
    this.fast_program_id = 0;
    if (reload) this.getTrainings();
  }

  openBannerSelect() {
    this.account = this.helpers.getLocallyStored('teacherAccount');
    const dialogRef = this.dialog.open(DialogSelectTrainingBannerComponent, {
      data: { account_status: parseInt(this.account.purchase.supertreinos_plan_id) },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== '') {
        console.log(`Dialog result (ok): ${result}`);
        this.cover_url_forceupdate = true;
        this.training.cover_path = result;
        this.training.cover_url = result;
      }
      console.log(`Dialog result (no banner): ${result}`);
    });
  }

  getTrainings() {
    // console.log(this.program_id);
    if (this.program_id === 0) {
      return;
    }
    this.loading = true;

    this.trainingProvider.trainings(this.program_id).then(
      (res: any) => {
        this.trainings = res.trainings;
        this.total_trainings = this.trainings.length;
        for (let i = 0; i < this.trainings.length; i++) {
          this.selected_trainings[i] = false;
        }
        // console.log(this.trainings);
        this.loading = false;
        this.allComplete = false;
        this.checkAllIndeterminate = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  selectTraining(id: any) {
    this.getTraining(id);
    this.setTrainingId.emit(id);
  }

  // updateSelectedTrainings(tid: any) {
  //   this.selected_trainings[tid] = !this.selected_trainings[tid];
  // }

  changeCheck(checked: boolean) {
    // console.log(this.selected_trainings);
    this.total_selected += (checked) ? + 1 : - 1;
  }

  updateAllComplete() {
    let selecteds: number = 0;
    this.selected_trainings.map((selected: boolean) => {
      if (selected) selecteds++;
    });
    if (selecteds === this.total_trainings) {
      this.checkAll.checked = true;
      this.checkAll.indeterminate = false;
    } else {
      if (this.selected_trainings.length > 0) {
        this.checkAll.indeterminate = true;
      }
    }
    if (selecteds === 0) {
      this.checkAll.indeterminate = false;
      this.checkAll.checked = false;
    }
  }

  setAll(checked: boolean) {
    if (this.selected_trainings == null) return;
    if (!checked) this.checkAllIndeterminate = false;
    let count: number = 0;
    for (let i = 0; i < this.trainings.length; i++) {
      this.selected_trainings[i] = checked;
      count++;
    }
    this.total_selected = (checked) ? count : 0;
  }

  getTraining(id: any) {
    this.loading = true;

    this.trainingProvider.training(id).then(
      (res: any) => {
        this.training = res.training;
        this.selected_training = this.training.id;

        if ((this.training.premium === 0) || (this.training.premium === '0')) {
          this.training.premium = false;
        } else {
          this.training.premium = true;
        }

        if ((this.training.published === 0) || (this.training.published === '0')) {
          this.training.published = false;
        } else {
          this.training.published = true;
        }

        this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.training.video_url);

        if (this.training.video_url === null || this.training.video_url === 'null') {
          // this.training.video_url = 'https://player.vimeo.com/video/';
          this.training.video_url = '';
        }

        this.config.headers = this.headers;

        this.config.url = this.api.API_URL + '/v2/trainings/' + id;
        this.config.params = {
          '_method': 'patch',
          'name': this.training.name,
          'video_url': this.training.video_url,
          'cover_path': this.training.cover_path,
        };
        if (this.training.goal > 0) {
          this.config.params.goal = this.training.goal;
        } else {
          this.config.params.goal = 2;
        }
        if (this.training.program_id > 0) {
          this.config.params.program_id = this.training.program_id;
        }

        // this.getGoals();
        this.groupsmuscleProv.index().then(
          (res: any) => {
            this.setTraining.emit(this.training);
            this.groups_muscle = res.groupsmuscle;
            this.loading = false;
          },
          (err: any) => {
            console.log(err);
            this.loading = false;
          }
        );

        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  getGoals() {
    this.loading = true;

    this.goalProvider.index().then(
      (res: any) => {
        this.goals = res.goals;
        // console.log(this.goals);
        if (this.training.id !== 0) {
          this.training.goal = (this.training.goals.length === 0 ? 0 : this.training.goals[0].id);
        }
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  addTraining() {
    // this.getGoals();
    this.selected_training = -1;
    this.sanitized_video_url = '';
    this.training = this.helpers.resetObject(this.training);
    this.training.points = 2;
    this.training.program_id = this.program_id;
    this.training.cover_url = this.API + 'img/TREINO-BANNER-PADRAO.jpg';
    // this.training.video_url = 'https://player.vimeo.com/video/';
    this.training.video_url = '';
    this.trainingName.reset();
    this.trainingVideoUrl.reset();
    // this.trainingVideoUrl.setValue('https://player.vimeo.com/video/');
    this.trainingVideoUrl.setValue('');
    // this.resetDropzoneUploads();
    this.updateDropzoneParams();
  }

  saveTraining() {
    this.loading = true;
    let valid = true;
    let el: any;
    this.updateTranslation();

    if (this.training.name === '') {
      this.trainingName.markAsTouched();
      valid = false;
    }
    if (this.helpers.validateURL(this.training.video_url)) {
      this.trainingVideoUrl.setErrors({'incorrect': true});
      valid = false;
    }
    if (valid === false) {
      this.loading = false;
      el = document.getElementById('iframeTrainingVideo');
      el.scrollIntoView();
      return false;
    }

    // console.log(this.training.id);
    this.training.pay = this.training.premium;
    this.training.cover_path = (this.training.cover_path === '') ? delete this.training.cover_path : this.training.cover_path;
    if (this.training.id > 0) {
      this.updateTraining();
    }
    if (this.training.id === 0) {
      if (this.user.account_type === 'teacher') this.training.published = 1;
        this.createTraining();
    }
    this.resetDropzoneUploads();
  }

  updateTraining() {
    const trainingUploaded = this.training;
    if (!this.cover_url_forceupdate) {
      delete trainingUploaded.cover_path;
    }
    delete trainingUploaded.cover_url;
    trainingUploaded.goal = this.training.goal;

    this.trainingProvider.patchTraining(trainingUploaded).then(
      (res: any) => {
        this.training = res.training;
        this.toastr.success(this.PAGE.atualizado_sucesso, '', this.helpers.toastrCfgs);
        // this.loading = false;
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        // this.loading = false;
        // this.goBack();
      });
  }

  createTraining() {
    this.trainingProvider.postTraining(this.training).then(
      (res: any) => {
        this.training = res.training;
        this.toastr.success(this.PAGE.cadastrado_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  deleteTraining(id = null) {
    this.deleteTrainingConfirmation(id);
  }

  deleteMassTraining() {
    let arr: any = [];
    // console.log(this.selected_trainings);
    this.selected_trainings.map((checked: any, idx) => {
      if (checked) {
        arr.push(this.trainings[idx].id);
      }
    });
    this.deleteMassTrainingConfirmation(arr);
  }

  deleteMassTrainingConfirmation(arrIds: any): void {
    const msg = this.PAGE.apagar_treinos;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_treinos_title, message: msg, confirmation: 'deletar', bt_ok: 'Apagar', bt_ok_class: 'bt-apagar'}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'deletar') {
        this.setTrainingId.emit(0);
        this.loading = true;
        this.trainingProvider.deleteMassTraining({tids: arrIds}).then(
          (_res: any) => {
            this.goBack();
            this.setAll(false);
            this.toastr.success(this.STRINGS.operacao_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  deleteTrainingConfirmation(id: any): void {
    const msg = this.PAGE.apagar_treino;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_treino_title, message: msg, confirmation: 'deletar'}
    });

    if (id === null) { id = this.training.id; }

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'deletar') {
        this.setTrainingId.emit(0);
        this.loading = true;
        this.trainingProvider.deleteTraining(id).then(
          (_res: any) => {
            this.goBack();
            this.toastr.success(this.PAGE.apagar_treino_confirmacao_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  trainingMassManage() {
    // console.log('seta data de publicacao do treino');
    const dialogRef = this.dialog.open(DialogSelectDateComponent, {
      data: { 
        showclear: true,
        title_innerhtml: this.PAGE.title_innerhtml,
        description: this.PAGE.description,
        label_date: this.PAGE.label_date,
        date_selected: null },
    });

    dialogRef.afterClosed().subscribe(result => { 
      // console.log(arr);
      // console.log('result: ' + JSON.stringify(result));
      if (result === undefined) return false;
      if (result !== '' || result !== null || result !== undefined) {
        let get = JSON.parse(JSON.stringify(result));
        if (get.date || get.clear) {
          let arr: any = [];
          // console.log(this.selected_trainings);
          let published_at = null;
          if (get.date) published_at = get.date.substring(-10, 10);
          if (get.clear) published_at = null;
          this.selected_trainings.map((checked: any, idx) => {
            if (checked) {
              arr.push({
                'id': this.trainings[idx].id,
                'name': this.trainings[idx].name,
                'goal': this.trainings[idx].goal,
                'cover_url': this.trainings[idx].cover_url,
                'published_at': published_at,
                'published': 1
              });
            }
          });

          this.total_selected = 0;
          this.selected_trainings = [];
          this.checkAll.indeterminate = false;
          this.checkAll.checked = false;

          console.log(arr);
          this.loading = true;
          this.trainingProvider.patchMassTraining(arr).then(
            (res: any) => {
              // this.training = res.training;
              this.toastr.success(this.PAGE.atualizado_sucesso, '', this.helpers.toastrCfgs);
              this.loading = false;
              this.getTrainings();
            },
            (err: any) => {
              console.log(err);
              this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
              this.loading = false;
          });
        }
      }
    });
  }

  trainingOrderUp(training: any) {
    this.trainingOrder(training, 'up');
  }

  trainingOrderDown(training: any) {
    this.trainingOrder(training, 'down');
  }

  trainingOrder(training: any, order: any) {
    delete training.cover_path;
    delete training.cover_url;
    training._order = order;

    this.loading = true;
    this.trainingProvider.patchTraining(training).then(
      (_res: any) => {
        this.toastr.success(this.PAGE.reordenar_treino_sucesso, '', this.helpers.toastrCfgs);
        this.toastr.success(this.PAGE.recarregando_treinos, '', this.helpers.toastrCfgs);
        this.getTrainings();
        // this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
        // this.goBack();
      });
  }

  trainingReorder(trainings: any) {
    let trainings_list = new Array;
    Object.assign(trainings_list, trainings);
    this.trainingProvider.reorderTraining(trainings_list.reverse()).then(
      (_res: any) => {
        this.toastr.success(this.PAGE.reordenar_treinos_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      });
  }

  trainingClone(training: any) {
    this.cloneTrainingConfirmation(training);
  }

  trainingMassClone() {
    let arr: any = [];
    this.selected_trainings.map((checked: any, idx: number) => {
      if (checked) {
        arr.push(this.trainings[idx].id);
      }
    });
    this.cloneTrainingConfirmation(arr);
  }

  cloneTrainingConfirmation(arr: any): void {
    let total_trainings = arr.length;
    let msg = '';
    let inner_msg = this.PAGE.clone_treino_msg;
    let confirmation = 'clonar';
    if ((this.total_trainings + total_trainings) > this.limit) {
      inner_msg = this.PAGE.clone_treino_msg2;
      confirmation = 'ok'
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.clone_treino_title, message: msg, confirmation: confirmation, inner_msg: inner_msg}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        return false;
      }

      if (result === 'clonar') {

        this.loading = true;
        this.trainings.map((training: any) => {
          arr.map((id: number) => {
            if (training.id === id) {
              this.trainingProvider.cloneTraining(training).then(
                (_res: any) => {
                  total_trainings--;
                  this.setAll(false);
                  this.toastr.success(this.PAGE.clone_treino_sucesso, '', this.helpers.toastrCfgs);
                  if (total_trainings < 1) this.goBack();
                },
                (err: any) => {
                  console.log(err);
                  this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
                  this.loading = false;
                });
            }
          })
        });

      }
    });
  }

  trainingMassShow() {
    // console.log(this.selected_trainings);
    let arr: any = [];
    this.selected_trainings.map((checked: any, idx: number) => {
      if (checked) {
        arr.push(this.trainings[idx].id);
      }
    });
    this.showTrainingConfirmation(arr);
  }
  showTrainingConfirmation(arr: any): void {
    let total_trainings = arr.length;
    let msg = '';
    let inner_msg = this.PAGE.publicar_treino;
    let confirmation = 'ocultar';

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.publicar_treino_title, message: msg, confirmation: confirmation, inner_msg: inner_msg}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        return false;
      }

      if (result === 'ocultar') {

        this.loading = true;
        this.trainings.map((training: any) => {
          arr.map((id: number) => {
            if (training.id === id) {
              this.trainingProvider.showTraining(training).then(
                (_res: any) => {
                  total_trainings--;
                  this.setAll(false);
                  this.toastr.success(this.PAGE.publicar_treino_sucesso, '', this.helpers.toastrCfgs);
                  if (total_trainings < 1) this.goBack();
                },
                (err: any) => {
                  console.log(err);
                  this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
                  this.loading = false;
                });
            }
          })
        });

      }
    });
  }  

  trainingMassHide() {
    let arr: any = [];
    this.selected_trainings.map((checked: any, idx: number) => {
      if (checked) {
        arr.push(this.trainings[idx].id);
      }
    });
    this.hideTrainingConfirmation(arr);
  }
  hideTrainingConfirmation(arr: any): void {
    let total_trainings = arr.length;
    let msg = '';
    let inner_msg = this.PAGE.ocultar_treino;
    let confirmation = 'ocultar';

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.ocultar_treino_title, message: msg, confirmation: confirmation, inner_msg: inner_msg}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        return false;
      }

      if (result === 'ocultar') {

        this.loading = true;
        this.trainings.map((training: any) => {
          arr.map((id: number) => {
            if (training.id === id) {
              this.trainingProvider.hideTraining(training).then(
                (_res: any) => {
                  total_trainings--;
                  this.setAll(false);
                  this.toastr.success(this.PAGE.ocultar_treino_sucesso, '', this.helpers.toastrCfgs);
                  if (total_trainings < 1) this.goBack();
                },
                (err: any) => {
                  console.log(err);
                  this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
                  this.loading = false;
                });
            }
          })
        });

      }
    });
  }

  trainingMassSend(program: any) {
    let arr: any = [];
    this.selected_trainings.map((checked: any, idx: number) => {
      if (checked) {
        arr.push(this.trainings[idx].id);
      }
    });
    this.sendTrainingConfirmation(arr, program);
  }

  sendTrainingConfirmation(arr: any, program: any): void {
    let total_trainings = arr.length;
    let msg = '';
    // let inner_msg = 'Os treinos serão enviados aleatoriamente abaixo dos originais.<br>Atente-se para reordená-los.';
    let inner_msg = this.PAGE.enviar_treino + '[' + program.name + ']?';
    let confirmation = 'enviar';

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.enviar_treino_title, message: msg, confirmation: confirmation, inner_msg: inner_msg}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        return false;
      }

      if (result === 'enviar') {

        this.loading = true;
        this.trainings.map((training: any) => {
          arr.map((id: number) => {
            if (training.id === id) {
              let movedTraining: any = {};
              Object.assign(movedTraining, training);
              movedTraining.new_program_id = program.id

              this.trainingProvider.cloneTraining(movedTraining).then(
                (_res: any) => {
                  total_trainings--;
                  this.setAll(false);
                  this.toastr.success(this.PAGE.enviar_treino_sucesso, '', this.helpers.toastrCfgs);
                  if (total_trainings < 1) this.goBack();
                },
                (err: any) => {
                  console.log(err);
                  this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
                  this.loading = false;
                });
            }
          })
        });

      }
    });
  }  

  PDFExportActive() {
    return ( (this.total_selected === 0) || (this.total_selected > 1) || (this.total_trainings == 0 && (this.program_id !== 0 || this.program_id === '')) )
  }
  PDFExport(full: boolean = false) {
    this.account = this.helpers.getLocallyStored('teacherAccount');
    let selected_trainings: any = [];
    this.selected_trainings.map((checked: any, idx: number) => {
      if (checked) {
        selected_trainings.push(this.trainings[idx]);
      }
    });
    let program = null;
    let trainings = null;
    
    if (full) {
      program = this.program;
      trainings = this.trainings;

      console.log(trainings);

      this.trainingProvider.trainingsFull(program.id).then(
        (res: any) => {
          trainings = res.trainings;
          console.log(trainings);
          const dialogRef = this.dialog.open(DialogExportPDFComponent, {
            panelClass: 'export-pdf-modal',
            width: '1200px',
            data: {selected_trainings: selected_trainings, all_trainings: trainings, program: program, account: this.account, 
              title: this.PAGE.exportar_pdf_title, message: 'teste', confirmation: 'Sim', bt_ok: 'ok', bt_cancel_hide: 'cancelar'}
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === 'Sim') {
              // console.log(customer);
            }
          });
        },
        (err: any) => {
          console.log(err);
        }
      );

    } else {
      const dialogRef = this.dialog.open(DialogExportPDFComponent, {
        panelClass: 'export-pdf-modal',
        width: '1200px',
        data: {selected_trainings: selected_trainings, all_trainings: trainings, program: program, account: this.account, 
          title: this.PAGE.exportar_pdf_title, message: 'teste', confirmation: 'Sim', bt_ok: 'ok', bt_cancel_hide: 'cancelar'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'Sim') {
          // console.log(customer);
        }
      });
    }

  }

  helpVideo() {
    let msg = this.PAGE.help_video;
    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }

  // dropzone events
  changeTrainingName() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams();
  }

  changeTrainingVideoUrl() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams();
    this.sanitized_video_url = '';
    if (this.training.video_url !== '' && this.training.video_url !== null && this.training.video_url !== 'https://player.vimeo.com/video/') {
      this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.training.video_url);
      this.training.video_url = this.training.video_url.replace('shorts/', 'watch?v=');
    }
  }

  onAddedFile(_args: any) {
    let valid = true;
    if (this.training.name === '') {
      this.loading = false;
      this.resetDropzoneUploads();
      valid = false;
      this.trainingName.markAsTouched();
    }
    if (valid === false) {
      return false;
    }
  }

  onSendingFile(_$event: any) {
    // this.training.cover_path = '';
    this.training.cover_url = false;
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    //
  }

  onUploadSuccess($event: any) {
    this.loading = false;
    // this.training = $event[1].training;
    this.training.id = $event[1].training.id;
    this.training.cover_url = $event[1].training.cover_url;
    this.training.cover_path = $event[1].training.cover_url;
    this.training.sort = $event[1].training.sort;
    this.setTrainingId.emit(this.training.id);
    this.setTraining.emit(this.training);
    this.toastr.success('Banner do Treino enviado com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.config.url = this.api.API_URL + '/v2/trainings/' + this.training.id;
    this.config.params = {
      '_method': 'patch',
      'name': this.training.name,
      'video_url': this.training.video_url,
      'cover_path': this.training.cover_path,
    };
    if (this.training.goal > 0) {
      this.config.params.goal = this.training.goal;
    } else {
      this.config.params.goal = 2;
    }
    if (this.training.program_id > 0) {
      this.config.params.program_id = this.training.program_id;
    }
  }

  resetDropzoneUploads(): void {
    this.helpers.deleteLocallyStored('cropOngoing');
    clearInterval(this.cropCheckInterval);

    // this.dzTrainingCover.directiveRef.reset(true);
  }

  updateDropzoneParams(): void {
    this.config.headers = this.headers;

    this.config.params = {
      'name': this.training.name,
      'video_url': this.training.video_url,
      'cover_path': (this.training.cover_path === '' ? 'fake_cover_path' : this.training.cover_path),
    };

    if (this.training.id > 0) {
      this.config.url = this.api.API_URL + '/v2/trainings/' + this.training.id;
      this.config.params._method = 'PATCH';
    } else {
      this.config.url = this.api.API_URL + '/v2/trainings/';
    }

    if (this.training.goal > 0) {
      this.config.params.goal = this.training.goal;
    } else {
      this.config.params.goal = 2;
    }

    if (this.training.program_id > 0) {
      this.config.params.program_id = this.training.program_id;
    }
  }

  updateTranslation() {
    const translations = (document.getElementById('translationFieldTrainings') as HTMLInputElement).value;
    // this.programForm.patchValue({ translations: translations});
    this.training.translations = translations;
  }

  processTranslation(value: any) {
    if (value) {
      (document.getElementById('translationFieldTrainings') as HTMLInputElement).value = JSON.stringify(value);
    }
  }


  selectAllChildrenGroupsMuscle(parent_id: any) {
    console.log(parent_id);
    console.log(this.selMuscles);

    this.selMuscles.options.forEach((opt, i) => {
      console.log(i);
      console.log(opt);
      opt.select();
    });

    let selects = document.getElementsByClassName('parent-' + parent_id) as any;
    for (let a = 0; a < selects.length; a++) {
      selects[a].ariaSelected = true;
    }

    console.log(selects);
  }

  toogleDetails() {
    this.detailsClass = (this.detailsClass === '') ? 'minimize' : '';
  }

  checkPublished(training: any) {
    const days = 1;
    let now = new Date(Date.now());
    now.setDate(now.getDate() + days);

    if (training.published === 0) return true;
    if (training.published_at !== null && new Date(training.published_at) >= now) return true;

    return false;
  }

  checkDisabled(limit: number = 0, canBeZero: boolean = false) {
    const totalOrZero = (canBeZero) ? false : (this.total_selected === 0);
    const hasTotalSelected = (limit > 0) ? (this.total_selected > limit) : totalOrZero;
    const state = ( 
      totalOrZero
      || (hasTotalSelected)
      || (this.total_trainings === 0 && (this.program_id !== 0 || this.program_id === ''))
    );
    return state;
  }

  closeTagMenu() {
    this.trigger.closeMenu(); // <-- put this in your dialog open method
  }

  saveTags() {
    for (let i = 0; i < this.trainingTags.length; i++) {
      let tag = this.trainingTags[i];
      let input = (document.getElementById('tagid-' + tag.id) as HTMLInputElement);
      if (input) this.trainingTags[i].text = (input.value !== '') ? { 'label': input.value } : { 'label': null };
    }
    this.saveTrainingsTags(this.trainingTags);

    let tids: any = [];
    this.selected_trainings.map((checked: any, idx: number) => {
      if (checked) tids.push(this.trainings[idx].id);
    });
    const data = { tag_id: this.selectedTag, tids: tids };
    this.saveTagToTrainings(data);    
  }

  saveTagToTrainings(data: any = {tag_id: 0, tids: []}) {
    // console.log(data);
    if (data.tids.length > 0) {
      this.trainingProvider.addTagToTrainings(data).then(
        (_res: any) => {
          // this.loading = false; 
          let msg = (data.tids.length === 1) ? this.PAGE.tag_adicionada : this.PAGE.tags_adicionadas;
          this.toastr.success(msg, '', this.helpers.toastrCfgs);
          this.getTrainings();
        },
        (err: any) => {
          console.log(err)
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          this.loading = false;
        }
      );  
    }
    this.closeTagMenu();
  }

  saveTrainingsTags(data: any) {
    // console.log(data);
    this.trainingProvider.addTags(data).then(
      (_res: any) => {
        // this.loading = false;
        let msg = this.PAGE.tags_atualizadas;
        this.toastr.success(msg, '', this.helpers.toastrCfgs);
        this.helpers.storeLocally('trainingTags', _res.tags)
      },
      (err: any) => {
        console.log(err)
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );    
  }

  toggleTags(program: any, v: boolean = false) {
    setTimeout(() => {
      // v, o valor do slider é invertido porque usamos o evento click nele pra executar a funcao,
      // logo pega o estado inicial dele, nao o final como acontece com o evento change
      program.show_tag = (v === false) ? 1 : 0;   
      this.programProvider.patchProgram(program).then(
        (_res: any) => {
          // this.loading = false;
          let msg = (program.show_tag) ? this.PAGE.tags_exibidas : this.PAGE.tags_ocultadas;
          this.toastr.success(msg, '', this.helpers.toastrCfgs);
        },
        (err: any) => {
          console.log(err)
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          this.loading = false;
        }
      );
    }, 500);
  }

  checkToggleTag(program: any) {
    return program.show_tag;
  }

  checkTagText(index: number = 0) {
    const t = this.trainingTags[index];
    if (t.text) {
      if (t.text.label) {
        return t.text.label;
      }
    }
    return '';
  }

}
