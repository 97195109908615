import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { AuthService } from '../../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserProvider } from '../../../providers/user/user';
import { I18nProvider } from '../../../providers/i18n/i18n';


@Component({
  selector: 'component-customer-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class CustomerHistoryComponent implements OnInit {
  protected headers: any;
  @Input() studentData: any = null;

  loading: boolean = false;
  history = [];

  mcalendar: any = [];
  donePrograms: number = 0;
  doneTrainings: number = 0;
  wPage: number = 0;
  dateRangeText: string = '';
  dateRangeYear: string = '';

  PAGE: any;
  STRINGS: any;

  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public studentProv: CustomerProvider,
    public userProv: UserProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('CustomerHistoryComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};

  }
  ngOnInit() {
    this.dateRangeText = '1 de janeiro - 1 de janeiro';
    this.mcalendar = [
      { day: 0, name: (this.STRINGS.fdomingo).slice(0, 3), status: 'todo' },
      { day: 1, name: (this.STRINGS.fsegunda).slice(0, 3), status: 'todo' },
      { day: 2, name: (this.STRINGS.fterca).slice(0, 3), status: 'todo' },
      { day: 3, name: (this.STRINGS.fquarta).slice(0, 3), status: 'todo' },
      { day: 4, name: (this.STRINGS.fquinta).slice(0, 3), status: 'todo' },
      { day: 5, name: (this.STRINGS.fsexta).slice(0, 3), status: 'todo' },
      { day: 6, name: (this.STRINGS.fsabado).slice(0, 3), status: 'todo' },
    ];
        
    this.getHistory(this.studentData.id);
  }

  ngOnChanges() {
    this.getHistory(this.studentData.id);
    // this.loading = true;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 400);
  }

  getHistory(id: any = 0) {
    this.loading = true;
    this.studentProv.getHistory(id).then(
      (res: any) => {
        this.loading = false;
        this.history = res.history;
        this.returnStatusDay(this.wPage, this.history);
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  toggleClass(item: any) {
    item.active = !item.active;
  }

  changeWPage(n: number = 0) {
    this.wPage = this.wPage + n;
    this.returnStatusDay(this.wPage, this.history);
  }

  returnStatusDay(w: number = 0, history: any = null) {
    const status = 'todo';
    const today = this.returnWeekDay();
    const weekDates = this.helpers.getWeekDates(w);

    this.mcalendar = [
      { day: 0, name: (this.STRINGS.fdomingo).slice(0, 3), status: 'todo' },
      { day: 1, name: (this.STRINGS.fsegunda).slice(0, 3), status: 'todo' },
      { day: 2, name: (this.STRINGS.fterca).slice(0, 3), status: 'todo' },
      { day: 3, name: (this.STRINGS.fquarta).slice(0, 3), status: 'todo' },
      { day: 4, name: (this.STRINGS.fquinta).slice(0, 3), status: 'todo' },
      { day: 5, name: (this.STRINGS.fsexta).slice(0, 3), status: 'todo' },
      { day: 6, name: (this.STRINGS.fsabado).slice(0, 3), status: 'todo' },
    ];

    // console.log(weekDates);
    const year1 = (weekDates[0]).slice(0, 4);
    const year2 = (weekDates[6]).slice(0, 4);
    if (year1 === year2) this.dateRangeYear = year1;
    if (year1 !== year2) this.dateRangeYear = year1 + ' - ' + year2;

    this.dateRangeText = (this.helpers.getMonthDay(weekDates[0]) + ' - ' + this.helpers.getMonthDay(weekDates[6]));

    this.mcalendar.forEach(idx => {
      if (idx.day < today) idx.status = 'null';
      if (w < 0) idx.status = 'null';
      if (w > 0) idx.status = 'todo';
    });

    this.donePrograms = 0;
    this.doneTrainings = 0;
    history.map((item: any) => {
      if (item.done === 1) { this.donePrograms++; }
      item.trainings.map((training: any) => {
        if (training.done === 1 || training.done === 2) {
          this.doneTrainings++;
          this.mcalendar.forEach(idx => {
            for (let index = 0; index < weekDates.length; index++) {
              const day = weekDates[index];
              if ((day) === (training.done_date.date).slice(0, 10)) {
                if (idx.day === index) idx.status = 'done';
              }
            }
          });
        }
      });
    });
    return status;
  }

  returnWeekDay() {
    const d = new Date();
    let day = d.getDay();
    if (this.wPage !== 0) day = -1;
    return day;
  }
  

}
