export const BR = {
  lang: 'br',
  code: 'pt-BR',
  bt_ok: 'Ok',
  bt_cancelar: 'Cancelar',
  bt_fechar: 'Fechar',
  bt_back: 'Voltar',
  bt_apagar: 'Apagar',
  bt_editar: 'Editar',
  bt_reordenar: 'Reordenar',
  bt_salvar: 'Salvar',
  bt_novo: 'Novo',
  bt_atualizar: 'Atualizar',
  bt_remover: 'Remover',
  bt_add: 'Adicionar',
  bt_filtrar: 'Filtrar',
  bt_selecionar: 'Selecionar',
  bt_clonar: 'Clonar',
  bt_enviar: 'Enviar',
  anterior: 'Anterior',
  proximo: 'Próximo',
  items_pagina: 'Itens por página',
  pagina: 'página',
  de: 'de',
  buscar: 'Buscar',
  msg_sim: 'Sim',
  msg_nao: 'Não',
  salvar: 'Salvar',
  homem: 'Homem',
  mulher: 'Mulher',
  masculino: 'Masculino',
  ambos: 'Ambos',
  feminino: 'Feminino',
  veja_mais: 'Veja mais',
  recolher: 'Recolher',
  carregando3pt: 'Carregando...',
  pronto: 'Pronto para exibir',
  link_termos: 'https://supertreinosapp.com.br/termos-de-uso-app-super-treinos/',
  link_privacidade: 'https://supertreinosapp.com.br/politica-privacidade-aplicativo/',
  link_cancelamento: 'https://supertreinosapp.com.br/politica-de-cancelamento/',
  mais_opcoes: 'Mais opções',
  opcoes: 'Opções',

  detalhes: 'Detalhes',
  dificuldade: 'Dificuldade',
  iniciante: 'Iniciante',
  intermediario: 'Intermediário',
  avancado: 'Avançado',
  expert: 'Expert',
  objetivo: 'Objetivo',
  semanas: 'Semanas',
  dias_por_semana: 'Dias por semana',
  tempo_treino: 'Tempo de treino',
  minutos: 'minutos',
  pontos_concluir: 'Pontos ao concluir',
  completo: 'completo',

  operacao_sucesso: 'A operação foi realizada com sucesso!',
  algo_errado: 'Algo não funcionou bem. Tente novamente.',
  indeterminado: 'Indeterminado',
  conteudo_premium: 'Conteúdo Premium',
  trava_generica: 'Ops! Esse é um conteúdo disponível apenas para assinantes <span class="name">Premium</span>. Faça sua assinatura agora!',
  trava_indisponivel: 'Ops! Esse conteúdo não está mais disponível! Caso tenha interesse nesse conteúdo, contate nosso suporte para saber mais.',

  fechar_app: 'Fechar',
  fechar_st: 'Fechar SuperTreinos',
  fechar_sc: 'Fechar ST Coach',
  fechar_sct: 'Fechar ST Coach Pro',
  certeza: 'Você tem certeza?',
  conta_blok: 'Sua conta está bloqueada. Contate o administrador.',

  campo_obrigatorio: 'Este campo é obrigatório',
  campo_invalido: 'O valor deste campo é inválido',

  programas: 'Programas',
  programa: 'Programa',
  treinos: 'Treinos',
  treino: 'Treino',
  exercicios: 'Exercícios',
  exercicio: 'Exercício',
  biblioteca: 'Biblioteca',
  prescricoes: 'Prescrições',
  nutricao: 'Nutrição',
  avaliacao: 'Avaliação',

  recurso_exclusivo: 'Esse recurso é exclusivo de assinantes Standard ou Pro. Gostaria de dar um upgrade no seu plano?',
  recurso_exclusivo_black: '<center>Esse recurso é exclusivo de assinantes Pro ou Black.</center><br><center>Vamos fazer o upgrade?</center>',
  recurso_exclusivo_black_only: 'Esse recurso é exclusivo de assinantes Black.',
  recurso_exclusivo_title: 'Recurso Exclusivo',
  gratis: 'Grátis',

  clique_arraste_reordenar: 'Clique e arraste para ordenar',
  ordernar_cima: 'Ordenar para cima',
  ordernar_baixo: 'Ordenar para baixo',
  sexo: 'Sexo',
  nome: 'Nome',
  titulo: 'Título',
  subtitulo: 'Subtítulo',
  antes_envio_poster: 'Antes de enviar um poster, adicione um TÍTULO',
  ou: 'Ou',
  escolha_poster: 'Escolha um poster',
  escolha_data: 'Escolha uma data',
  endereco_video: 'Endereço do vídeo',
  use_video_vimeo_youtube: 'Utilize vídeos do Youtube ou Vimeo',
  descricao: 'Descrição',
  minutos_por_dia: 'Minutos por dia',
  disponivel_planos: 'Disponíveis para os planos',
  grupos_musculares: 'Grupos musculares',

  dz_remover_imagem: 'REMOVER IMAGEM',
  dz_cancelar_envio: 'CANCELAR ENVIO',
  dz_confirma_cancelar_envio: 'CONFIRMA CANCELAR ENVIO?',
  dz_arquivo_invalido: 'ARQUIVO INVÁLIDO',

  nao_filtrar: 'Não Filtrar',
  filtros: 'Filtros',
  limpar_filtros: 'Limpar Filtros',
  publicado: 'Publicado',
  oculto: 'Oculto',
  nao_publicado: 'Não Publicado',
  reservado: 'Reservado',

  ativar: 'Ativar',
  ativo: 'Ativo',
  status: 'Status',
  bloquear: 'Bloquear',
  expiracao: 'Expiração',
  bloqueado: 'Bloqueado',
  cadastro: 'Cadastro',
  historico: 'Histórico',
  notas: 'Notas',
  arquivos: 'Arquivos',

  aluno: 'aluno',
  paciente: 'paciente',
  dia: 'dia',
  dias: 'dias',
  peso: 'peso',

  limite_maximo:  'Desculpe, você atingiu o limite máximo de cadastros permitidos nesta conta. ' +
  'Entre em contato com o administrador do sistema para obter mais informações ' +
  'ou para solicitar uma atualização do seu nível de acesso.',
  limite_registros: 'Ops! você atingiu o limite de cadastros na sua conta atual. Faça um upgrade ou apague alguns cadastros antigos.',
  link_copiado: 'O link foi copiado para Área de Transferência',
  link_copiado_erro: 'Seu sistema não permite a cópia automatizada. Copie e cole manualmente.',

  por_alimentos: 'Por alimentos',
  por_textos_livres: 'Por textos livres',
  dieta: 'Dieta',
  exportar_pdf: 'Exportar PDF',
  assinar_black: 'Assinar Black',

  link_transferencia: 'O link foi copiado para Área de Transferência',
  copia_negada: 'Seu sistema não permite a cópia automatizada. Copie e cole manualmente.',

  fsegunda: 'Segunda',
  fterca: 'Terça',
  fquarta: 'Quarta',
  fquinta: 'Quinta',
  fsexta: 'Sexta',
  fsabado: 'Sábado',
  fdomingo: 'Domingo',

  // MENUS
  MenuUI: {
    menu_idioma: 'Idioma',
    menu_minhaconta: 'Minha Conta',
    menu_suporte: 'Suporte',
    menu_sair: 'Sair',
    menu_dashboard: 'Dashboard',
    menu_programas: 'Programas',
    menu_alunos: 'Alunos',
    menu_nutricao: 'Nutrição',
    menu_biblioteca: 'Biblioteca',
    menu_arquivos: 'Arquivos',
    menu_loja: 'Loja',
    menu_tutoriais: 'Tutoriais',
    menu_apps: 'Aplicativos',
    ptbr: 'Português',
    enus: 'Inglês',
    eses: 'Espanhol',
    assinatura_pendente: 'Assinatura Pendente',
    assinatura_pendente_regularize: 'Regularize a assinatura dentro de 3 dias para evitar bloqueios de alunos',
    assinatura_pendente_gerenciar: 'Gerenciar Assinatura',
    assinatura_pendente_regularizada: 'Assinatura regularizada com sucesso!',
  },

  // LOGIN
  LoginPage: {
    hello: 'Olá',
    acesse: 'Acesse sua conta',
    email: 'E-mail',
    senha: 'Senha',
    entrar: 'Entrar',
    proxima: 'Próxima',
    anterior: 'Anterior',
    sem_conta: 'Ainda não tem uma conta de Personal?',
    sem_conta_buscar_shape: 'Bora buscar o shape!',
    digite_email: 'Digite seu email para logar no sistema.',
    digite_senha: 'Você precisa digitar sua senha.',
    cadastrese: 'Cadastre-se aqui!',
    criarconta: 'Criar conta agora',
    gratis: 'É grátis!',
    erro_login: 'Login/Senha incorretos.',
    senha_invalida: 'Senha inválida.',
    user_notfound: 'Usuário não encontrado.',
    login: 'Login',
    esqueceu: 'Esqueceu a senha?',
    erro_login_user: 'Usuário não encontrado. Verifique se o email de login está correto.',
    erro_login_user_st: 'Esta conta só é valida para uso no app SuperTreinos.',
    erro_login_user_sc: 'Esta conta só é valida para uso no app STCoach.',
    erro_login_wait: 'Usuário aguardando ativação.',
    erro_login_block: 'Ops! Seu acesso está bloqueado. Favor entrar em contato com o seu Personal Trainer.',
    erro_login_pwd: 'Senha incorreta.',
    erro_login_empty: 'Usuário e/ou senha não informados.',
    erro_login_generic: 'Algo não funcionou bem. Tente novamente.',
  },

  // CADASTRO
  RegistrationPage: {
    cadastro: 'Cadastro',
    ficha: 'Cadastro para treinadores e profissionais da saúde. <br> Os campos com (*) são obrigatórios.',
    ficha_nutri: 'Cadastro para Nutricionistas. <br> Os campos com (*) são obrigatórios.',
    not_customer: 'Não é permitido cadastro de alunos.',
    nome: 'Nome completo',
    email: 'E-mail',
    email_confirme: 'Confirme o E-mail',
    ddi: 'DDI',
    celular: 'Celular',
    nascimento: 'Data de Nascimento',
    sexo: 'Sexo',
    homem: 'Homem',
    mulher: 'Mulher',
    senha: 'Senha (no mínimo 6 dígitos)',
    confirme: 'Confirme a senha',
    ao_cadastrar: 'Li e concordo com os',
    termos: 'termos de uso',
    privacidade: 'política de privacidade',
    vogal_e: 'e',
    cancelamento: 'política de cancelamento',
    cadastrar: 'Criar Cadastro',
    sucesso: 'Seja bem-vindo, [name]. Seu cadastro foi realizado.',
    profissao: 'Profissão',
    edfisica: 'Profissional de Educação Física',
    fisio: 'Fisioterapeuta',
    nutri: 'Nutricionista',
    estud: 'Estudante',
    cpf: 'CPF',
    cref: 'CREF',
    crefito: 'CREFITO',
    crn: 'CRN',
    nutriname: 'Nome do profissional de nutrição',
    cpf_not_valid: 'O CPF não foi digitado corretamente!',
    cref_not_empty: 'O campo CREF não pode ficar vazio!',
    crefito_not_empty: 'O campo CREFITO não pode ficar vazio!',
    crn_not_empty: 'O campo CRN não pode ficar vazio!',
    nutriname_not_empty: 'O campo "Nome do profissional de nutrição" não pode ficar vazio!',
    sou_estrangeiro: 'Sou estrangeiro e declaro que estou qualificado para praticar a profissão de acordo com as leis do meu país.',
    senha_diferente: 'As senhas não conferem!',
    email_diferente: 'Os emails não conferem!',
    escolha_prof: 'Você precisa selecionar ao menos uma profissão!',
    email_cadastrado: 'E-mail já existente! Favor cadastrar outro e-mail',
    campo_obrigatorio: 'Este campo é obrigatório',
    campo_invalido: 'O valor deste campo é inválido',
    ddd: 'DDD + número',
  },

  // DASHBOARD
  DashboardPage: {
    assinatura: 'Assinatura',
    assinar: 'Assinar',
    vencimento: 'Vencimento',
    renovar: 'Renovar',
    lista_espera: 'Lista de Espera',
    nenhum_aluno_espera: 'Nenhum Aluno na espera',
    cliente: 'Cliente',
    clientes: 'Clientes',
    em_espera: 'em espera',
    ver_lista: 'Ver lista',
    veja_como_funciona: 'veja como funciona',
    link_captacao: 'Link de Captação',
    link_copiado: 'O link foi copiado para Área de Transferência',
    link_copiado_erro: 'Seu sistema não permite a cópia automatizada. Copie e cole manualmente.',
    form_cadastro: 'Formulário de cadastro: crie sua conta',
    ver_todos: 'ver todos',
    alunos_cadastrados: 'Alunos Cadastrados',
    pacientes_cadastrados: 'Status dos Pacientes',
    ativos: 'Ativos',
    expirados: 'Expirados',
    bloqueados: 'Bloqueados',
    clientes_premium: 'Clientes Premium',
    tempo_consultoria: 'Tempo de Consultoria',
    pacientes: 'Pacientes',
    primeiro_aluno: 'Cadastre seu primeiro aluno',
    primeiro_paciente: 'Cadastre seu primeiro paciente',
    bloqueado: 'Bloqueado',
    perfil: 'Perfil',
    no_phone: 'Sem telefone',
    tel_nao_cadastrado: 'O telefone do cliente não foi cadastrado!',
    clientes_nao_retornados: 'Clientes não retornados. Tente novamente',
    cadastro_primeiro: 'Cadastre seu 1.º programa',
    pg_objetivos: 'Objetivos',
    pg_dificuldade: 'Dificuldade',
    pg_programas_cadastrados: 'Programas Cadastrados',
    total_de: 'Total de',
    treinos_concluidos_fluxo: 'Treinos concluídos dentro do fluxo da agenda',
    nenhum_treino_concluido: 'Nenhum Treino concluído',
    ultimos_treinos: 'Útimos Treinos',
    ver: 'Ver',
    ver_7: 'Ver 7 dias',
    ver_15: 'Ver 15 dias',
    ver_30: 'Ver 30 dias',
    dias_sem_treinos_concluidos: 'Dias sem treinos concluídos não são exibidos no gráfico',
    ver_como_funciona: 'veja como funciona',
    treinos_concluidos: 'Treinos Concluídos',
  },

  // PROGRAMAS
  ProgramasPage: {
    programas: 'Programas',
    treinos: 'Treinos',
    exercicios: 'Exercícios',
    editar_programa: 'Editar o programa',
    editar_treino: 'Editar o treino',
    editar_exercicio: 'Editar o exercício',
    click_enviar_banner: 'Clique aqui para enviar o banner do Programa',
    arraste_banner: 'Arraste o banner do Programa até aqui',
    click_enviar_capavideo: 'Clique aqui para enviar a capa do video',
    arraste_capavideo: 'Arraste a capa do video até aqui',
    click_enviar_featured: 'Clique aqui para enviar o banner de destaque (1280px de largura por 720px de altura)',
    arraste_featured: 'Arraste o banner de destaque até aqui (1280px de largura por 720px de altura)',
    atualizado_sucesso: 'Programa atualizado com sucesso!',
    cadastrado_sucesso: 'O Programa foi cadastrado com sucesso!',
    limite_maximo:  'Desculpe, você atingiu o limite máximo de cadastros permitidos nesta conta. ' +
                    'Entre em contato com o administrador do sistema para obter mais informações ' +
                    'ou para solicitar uma atualização do seu nível de acesso.',
    limite_programas: 'Ops! você atingiu o limite de programas na sua conta atual. Faça um upgrade ou apague alguns programas antigos.',
    bt_upgrade: 'UPGRADE',
    cadastro_programas: 'Cadastro de Programas',
    clone_confirmation: '<p>Alguns programas podem levar até 15 minutos para clonar.</p> <p>Deseja realmente clonar este Programa?</p>',
    clone_confirmation_title: 'Clonar Programa',
    clone_confirmation_success: 'O Programa está sendo clonado. Esse processo pode levar um tempo, verifique o Programa clonado mais tarde.',
    apagar_programa: 'Deseja realmente apagar este Programa? Todos os Treinos e Exercícios deste Programa serão apagados também!',
    apagar_programa_title: 'Apagar Programa',
    apagar_programa_placeholder: 'escreva DELETAR, em caixa alta',
    apagar_programa_confirmacao: 'DELETAR',
    apagar_programa_confirmacao_sucesso: 'O Programa e todos os seus Treinos e Exercícios foram apagados com sucesso!',
    apagar_clone: 'Ao interromper a clonagem, todos os Treinos e Exercícios deste Programa serão apagados.',
    apagar_clone_title: 'Interromper clonagem',
    apagar_clone_sucesso: 'A clonagem foi interrompida com sucesso!',
    reordenar_programa_sucesso: 'Programa reordenado com sucesso!',
    reordenar_programas_sucesso: 'Programas reordenados com sucesso!',
    help_video: '<p>Se necessário coloque um vídeo explicativo do programa para o seu cliente.</p>' +
                '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Saiba mais</a></p>',
    help_clone: '<p>Por conta do volume de conteudo, pode acontecer algum erro. Cancele.</p>' +
                '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Saiba mais</a></p>',
    banner_upload_sucesso: 'Banner do Programa enviado com sucesso!',
    thumb_upload_sucesso: 'Thumb do Programa enviado com sucesso!',
    menos_minuto: 'menos de 1 minuto',
    minutos: 'minutos',
    lista_programas_aluno: 'A lista de Programas do Aluno foi atualizada.',
    lista_arquivos_aluno: 'A lista de Arquivos do Aluno foi atualizada.',
    programa_listado: 'programa listado',
    programas_listados: 'programas listados',
    disponiveis: 'disponíveis',
    como_criar_treinos: 'Como criar os treinos',
    processando_clonagem: 'processando clonagem',
    aguarde_15min: 'Favor aguardar até 15 minutos.',
    atualize_tela: 'Após esse tempo, atualize a tela para o programa ser exibido.',
    selecione_alunos: 'Selecione alunos para este programa',
    nenhum_aluno: 'Nenhum aluno cadastrado',
    novo_aluno: 'novo aluno',
    enviar_todos: 'Enviar para todos',
    selecionado: 'Selecionado',
    ocultado: 'Ocultado',
    limpar_busca: 'Limpe a Busca para reabilitar a ordenação',
    clique_arraste_reordenar: 'Clique e arraste para ordenar',
    ordernar_cima: 'Ordenar para cima',
    ordernar_baixo: 'Ordenar para baixo',
    titulo: 'Título',
    antes_envio_poster: 'Antes de enviar um poster, adicione um TÍTULO ao Programa.',
    poster_programa: 'Poster do Programa',
    ou: 'Ou',
    escolha_poster: 'Escolha um poster',
    detalhes: 'Detalhes',
    endereco_video: 'Endereço do vídeo',
    use_video_vimeo_youtube: 'Utilize vídeos do Youtube ou Vimeo',
    descricao: 'Descrição',
    objetivo: 'Objetivo',
    dias_por_semana: 'Dias por semana',
    minutos_por_dia: 'Minutos por dia',
    selecione_capa: 'Selecione uma capa para o Programa',
    disponivel_planos: 'Disponíveis para os planos',
  },

  // TREINOS
  TreinosPage: {
    click_enviar_banner: 'Clique e envie o banner do Treino',
    arraste_banner: 'Arraste o banner do Treino até aqui',
    atualizado_sucesso: 'Treino atualizado com sucesso!',
    cadastrado_sucesso: 'O Treino foi cadastrado com sucesso!',
    limite_maximo:  'Desculpe, você atingiu o limite máximo de cadastros permitidos nesta conta. ' +
                    'Entre em contato com o administrador do sistema para obter mais informações ' +
                    'ou para solicitar uma atualização do seu nível de acesso.',
    limite_programas: 'Ops! você atingiu o limite de programas na sua conta atual. Faça um upgrade ou apague alguns programas antigos.',
    apagar_treino: 'Deseja realmente apagar este Treino? Todos os Exercícios deste Treino serão apagados também!',
    apagar_treino_title: 'Apagar Treino',
    apagar_treinos: 'Deseja realmente apagar o(s) Treino(s) selecionado(s)? Os Exercícios do(s) Treino(s) selecionado(s) também serão apagados!',
    apagar_treinos_title: 'Apagar Treinos',
    apagar_treino_placeholder: 'escreva DELETAR, em caixa alta',
    apagar_treino_confirmacao: 'DELETAR',
    apagar_treino_confirmacao_sucesso: 'O Treinos e todos os seus Exercícios foram apagados com sucesso!',
    title_innerhtml: '<img src="assets/imgs/icon-timer.svg" alt="">Escolha a data para este treino ser liberado.',
    description: 'Os treinos programados serão liberados e exibidos automaticamente dentro do programa do aluno.',
    label_date: 'Expiração Atual:',
    reordenar_treino_sucesso: 'Programa reordenado com sucesso!',
    reordenar_treinos_sucesso: 'Programas reordenados com sucesso!',
    recarregando_treinos: 'Recarregando os treinos...',
    clone_treino_msg: 'Os treinos serão clonados aleatoriamente abaixo dos originais.<br>Atente-se para reordená-los.',
    clone_treino_msg2: 'Limite de Treinos atingido. Apague algum Treino se quiser fazer algum clone.',
    clone_treino_title: 'Clonar Treino',
    clone_treino_sucesso: 'Treino clonado com sucesso!',
    publicar_treino: 'Os treinos selecionados estarão disponíveis para os alunos. Deseja prosseguir?',
    publicar_treino_title: 'Publicar Treino',
    publicar_treino_sucesso: 'Treino publicado com sucesso!',
    ocultar_treino: 'Os treinos selecionados serão ocultados para os alunos. Deseja prosseguir?',
    ocultar_treino_title: 'Ocultar Treino',
    ocultar_treino_sucesso: 'Treino ocultado com sucesso!',
    enviar_treino: 'Deseja enviar uma cópia deste treino para o programa',
    enviar_treino_title: 'Enviar Treino',
    enviar_treino_sucesso: 'Treino enviado com sucesso!',
    exportar_pdf_title: 'Exportar PDF',
    help_video: '<p>Se necessário coloque um vídeo explicativo do treino para o seu cliente.</p>' +
                '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Saiba mais</a></p>',
    tag_adicionada: 'Tag adicionada ao treino.',
    tags_adicionadas: 'Tags adicionadas ao treino.',
    tags_atualizadas: 'Tags atualizadas.',
    tags_exibidas: 'As tags desses treinos serão exibidas para os alunos.',
    tags_ocultadas: 'As tags desses treinos ficarão ocultas para os alunos.',
    gerar_pdf_completo: 'Gerar PDF do programa completo',
    programa_sem_treino: 'Este programa não contém nenhum treino. Vamos criar?',
    tp_apagar_treino: 'Apagar treino',
    tp_clonar_treino: 'Clonar treino',
    tp_ocultar_selecionados: 'Ocultar selecionados',
    tp_exibir_selecionados: 'Exibir selecionados',
    tp_exportar_clone: 'Exportar clone',
    tp_agendar_liberacao: 'Agendar liberação',
    tp_exportar_pdf: 'Exportar treino selecionado para PDF',
    tp_tag_id: 'Tag de Identificação',
    enviar_programa: 'Enviar para qual programa?',
    tag_title: 'Tags de identificação',
    tag_subtitle: 'Selecione uma tag para identificar o tipo de treino para o aluno (opcional):',
    tag_list: 'Exibir as tags para o aluno no app',
    sem_tag: 'Sem tag (padrão)',
    sem_descricao: 'Sem descrição',
    selecionados: 'selecionados:',
    ocultado: 'Ocultado',
    programado: 'Programado',
    para: 'para',
    liberado: 'Liberado',
    em: 'em',
    listado: 'Listado',
  },

  // EXERCICIOS
  ExerciciosPage: {
    click_enviar_banner: 'Clique aqui para enviar o banner do Exercício',
    arraste_banner: 'Arraste o banner do Exercício até aqui',
    banner_upload_sucesso: 'Banner do Exercício enviado com sucesso!',
    atualizado_sucesso: 'Exercício atualizado com sucesso!',
    cadastrado_sucesso: 'O Exercício foi cadastrado com sucesso!',
    apagar_exercicio: 'Deseja realmente apagar este Exercício?',
    apagar_exercicio_title: 'Apagar Exercício',
    apagar_exercicio_placeholder: 'escreva DELETAR, em caixa alta',
    apagar_exercicio_confirmacao: 'DELETAR',
    apagar_exercicio_confirmacao_sucesso: 'O Exercício foi apagado com sucesso!',
    reordenar_exercicio_sucesso: 'Exercício reordenado com sucesso!',
    reordenar_exercicios_sucesso: 'Exercícios reordenados com sucesso!',
    campo_intervalo: 'O campo Intervalo aceita apenas números',
    campo_nome_obrigatorio: 'O campo Nome é obrigatório',
    serie_cadastro_sucesso: 'A Série foi cadastrada com sucesso!',
    serie_atualizado_sucesso: 'Série atualizada com sucesso!',
    apagar_serie: 'Deseja realmente apagar esta Série? os exercícios não serão apagados, apenas o agrupamento.',
    apagar_serie_title: 'Apagar Série',
    apagar_serie_placeholder: 'escreva DELETAR, em caixa alta',
    apagar_serie_confirmacao: 'DELETAR',
    apagar_serie_confirmacao_sucesso: 'A Série foi apagada com sucesso!',
    atualizar_exercicio_a: 'Deseja realmente atualizar o campo',
    atualizar_exercicio_b: 'de todos os exercícios?', 
    atualizar_exercicio_title: 'Atualizar Exercícios',
    atualizar_exercicio_sucesso: 'Os exercícios foram atualizados com sucesso!',
    ajuda_intervalo:  '<p>No campo intervalo coloque apenas números por segundo.</p>' +
                      '<p class="red">Não use aspas.</p>',
    ajuda_video:  '<p>Se necessário coloque um vídeo explicativo do treino para o seu cliente.</p>' +
                  '<p><a href="https://www.stcoach.app/tutoriais-videos/" target="_blank">Saiba mais</a></p>',
    apagar_exercicios: 'Deseja realmente apagar o(s) Exercício(s) selecionado(s)?',
    apagar_exercicios_title: 'Apagar Exercícios',
    apagar_exercicios_sucesso: 'A operação foi realizada com sucesso!',
    clonar_exercicio: 'Deseja realmente clonar o(s) Exercício(s) selecionado(s)?',
    o_limite_de: 'O limite de ',
    limite_exercicios: ' exercícios será atingido com essa clonagem. Apague algum exercício ou selecione menos exercícios se quiser fazer algum clone.',
    clonar_exercicio_title: 'Clonar Exercício',
    clonar_exercicio_sucesso: 'Clonagem realizada com sucesso!',

    series_repetition: 'Séries x Repetições',
    interval: 'Intervalo',
    bt_agrupar: 'Agrupar',
    nenhum_exercicio: 'Este treino não contém nenhum exercício. Para adicioná-los, clique em "Bibliotecas".',
    treinos_selecionados: 'Treinos selecionados:',
    salvar_serie: 'Salvar Série',
    selecione_cor: 'Selecione uma cor para a Série:',
    deletar_exercicio: 'Deletar Exercício',
    sugestao_carga: 'Carga:',
    intervalo: 'Intervalo:',
    antes_enviar_poster: 'Antes de enviar um banner, adicione um TÍTULO ao Exercício.',
    imagem_miniatura: 'Imagem miniatura do Exercício',
    nenhum_video: 'Nenhum Vídeo Carregado',
    aplicar_todos: 'Aplicar em todos',
    sugestao_carga_opcional: 'Sugestão de carga (opcional)',

    adicionar_exercicio_biblioteca: 'Tem certeza que deseja adicionar ao seu treino os exercícios selecionados?',
    cadastro_exercicios: 'Cadastro de Exercícios',
    campos_atualizados: 'Campos atualizados com sucesso!',
    minha_biblioteca: 'Minha Biblioteca',
    exercicios_listados: 'exercícios listados',
    exercicio_listado: 'exercício listado',
    tipo: 'Tipo',
    metodos: 'Métodos',
    musculos: 'Músculos',
    equipamentos: 'Equipamentos',
    exercicios_selecionados: 'Exercícios selecionados',
    add_ao_treino: 'add ao treino',
    limpar_selecao: 'limpar seleção',
    adicionar_video: 'você pode adicionar e organizar seus vídeos na Minha Biblioteca.',
    saiba_mais_biblioteca: 'Saiba mais em Menu > Minha Biblioteca.',
    novo_treino: 'NOVO TREINO',
    editar_treino: 'EDITAR TREINO',
  },

  // ALUNOS
  AlunosPage: {
    apagar_aluno_a: 'Deseja realmente apagar o Aluno <b class="name">',
    apagar_aluno_b: '</b>? Todo o progresso dele será apagado!',
    apagar_aluno_title: 'Apagar Aluno',
    apagar_aluno_msg: 'escreva DELETAR, em caixa alta',
    apagar_aluno_placeholder: 'escreva DELETAR, em caixa alta',
    apagar_aluno_confirmacao: 'DELETAR',
    apagar_aluno_confirmacao_sucesso_a: 'O Aluno  (',
    apagar_aluno_confirmacao_sucesso_b: ') foi apagado com sucesso!',
    expiracao: '<img src="assets/imgs/timer.svg" alt="">Marque o tempo de consultoria do ',
    expiracao_lable: 'Expiração Atual:',
    expiracao_msg_a: 'A validade do plano do aluno (',
    expiracao_msg_b: ') foi atualizada com sucesso!',
    cliente_ativo_bloqueado: ') foi bloqueado com sucesso!',
    cliente_ativo_desbloqueado: ') foi ativado com sucesso!',
    cliente_apagar: 'Tem certeza que deseja apagar as contas expiradas?',
    cliente_apagar_title: 'Apagar alunos expirados',
    cliente_apagar_sucesso: 'As contas foram apagados com sucesso!',
    cliente_desbloquear: 'Tem certeza que deseja desbloquear as contas não expiradas?',
    cliente_desbloquear_title: 'Desbloquear alunos ativos',
    cliente_desbloquear_sucesso: 'As contas foram desbloqueados com sucesso!',
    limite_maximo:  'Desculpe, você atingiu o limite máximo de cadastros permitidos nesta conta. ' +
                    'Entre em contato com o administrador do sistema para obter mais informações ' +
                    'ou para solicitar uma atualização do seu nível de acesso.',
    limite_clientes: 'Ops! você atingiu o limite de contas na sua assinatura atual. Faça um upgrade ou apague algumas contas antigas.',
    cadastro_title: 'Cadastro',
    nenhuma_conta: 'Nenhuma conta foi selecionada',
    contas_bloqueadas_sucesso: 'As contas foram bloqueadas com sucesso!',
    todas_as_contas: '<p class="danger">ATENÇÃO!<br> Você selecionou TODOS as contas!</p>',
    cinco_contas: '<p class="danger">ATENÇÃO!<br> Você selecionou mais de 5 contas!</p>',
    contas_apagar: '<p>Deseja realmente apagar as contas selecionadas? Todos os progressos e atividades dessas contas serão apagadas!</p>',
    contas_apagar_title: 'Apagar contas',
    contas_apagar_placeholder: 'escreva DELETAR, em caixa alta',
    contas_apagar_confirmacao: 'DELETAR',
    contas_apagar_sucesso: 'As contas foram apagados com sucesso!',
    contas_apagar_falha: 'A ação foi cancelada. Verifique se digitou corretamente o texto de confirmação.',
    avalicao_title: 'Avaliação Física',
    precadastro_ativadas_sucesso: 'As contas foram ativadas com sucesso!',
    precadastro_nao_selecionadas: 'Nenhum candidato foi selecionado',
    precadastro_apagar: 'Deseja realmente apagar esses candidatos?',
    precadastro_apagar_title: 'Apagar Candidatos',
    precadastro_apagar_sucesso: 'Os candidatos foram apagados com sucesso!',
    precadastro_aceite: 'Tem certeza que deseja adicionar este pré-cadastro na sua lista de ',
    precadastro_aceite_title: 'Aceitar novo ',
    precadastro_aceite_sucesso: 'O pré-cadastro foi aceito com sucesso!',
    precadastro_remover: 'Tem certeza que deseja remover este pré-cadastro da sua lista de espera?',
    precadastro_remover_title: 'Rejeitar novo ',
    precadastro_remover_sucesso: 'O pré-cadastro foi recusado com sucesso!',
    conta_recuperacao_stcoach: '<p>A recuperação de contas é um recurso exclusivo dos planos Pro e Black.</p> <p>Deseja fazer o "upgrade" do seu plano?</p>',
    conta_recuperacao_mydietpro: '<p>A recuperação de contas é um recurso exclusivo dos planos Lite e Gold.</p> <p>Deseja fazer o "upgrade" do seu plano?</p>',
    conta_recuperacao_title: 'Recuperação de contas',
    conta_recuperacao_excede: '<p><center>A recuperação desta conta excede o seu plano atual.</p> <p>Gostaria de assinar um plano superior?</center></p>',
    conta_recuperacao_excede_2: '<p><center>A recuperação destas contas excede o seu plano atual.</p> <p>Gostaria de assinar um plano superior?</center></p>',
    conta_recuperacao_single: 'Tem certeza que deseja restaurar esta conta?',
    conta_recuperacao_multi: 'Tem certeza que deseja restaurar estes contas?',
    conta_recuperacao_none: 'Nenhuma conta foi selecionada',
    conta_recuperacao_title_2: 'Restaurar conta',
    conta_recuperacao_sucesso: 'Ação executada com sucesso!',
    conta_exclusao: 'Tem certeza que deseja apagar completamente esta conta? Após essa ação todos os dados serão permanentemente apagados!',
    conta_exclusao_nenhuma: 'Nenhuma conta foi selecionada',
    conta_exclusao_confirmacao: 'Tem certeza que deseja apagar completamente estas contas? Após essa ação todos os dados serão permanentemente apagados!',
    conta_exclusao_title: 'Excluir permanentemente',
    conta_exclusao_sucesso: 'Ação executada com sucesso!',
    editar_link_captacao: 'Editar link de captação',
    aceitos: 'Aceitos',
    lista_espera: 'Lista de Espera',
    excluidos: 'Excluídos',
    opcoes: 'Opções',
    reativar_contas: 'Reativar todos as contas desativadas',
    selecionados: 'selecionados:',
    saiba_tudo_sobre: 'Saiba tudo sobre',
    aceitar_selecionados: 'Aceitar Selecionados',
    recusar_selecionados: 'Recusar Selecionados',
    recuperar_selecionados: 'Recuperar Selecionados',
    eliminar_selecionados: 'Eliminar Selecionados',
    data_inscricao: 'Data de Inscrição',
    data_exclusao: 'Data de Exclusão',
    lista_espera_vazia: 'Não há contas na lista de espera.',
    recurso_rest_conta_h: 'O recurso de restauração de contas é exclusivo para os planos Pro e Black.',
    recurso_rest_conta_p: 'As contas excluídas poderão ser recuperados em até 7 dias. Após esse período elas serão totalmente apagadas do sistema e não poderão ser recuperadas.',
    recurso_rest_conta_h_diet: 'O recurso de restauração de contas é exclusivo para o plano Lite e Gold.',
    recurso_rest_conta_p_diet: 'as contas excluídas poderão ser recuperados em até 7 dias. Após esse período elas serão totalmente apagadas do sistema e não poderão ser recuperadas.',
    contas_selecionadas: 'Contas selecionadas:',
    dias_restantes: 'Dias restantes',
    lista_exclusao_vazia: 'Não há contas na lista de exclusão.',
    menu_aluno: 'Menu do aluno',
  },

  DialogValuationComponent: {
    bioimpedancia: 'Bioimpedância',
    dados_balanca: 'Dados da balança',
    db_gordura: 'Gordura',
    musculo_esqueletico: 'Músculo Esquelético',
    idade_corporal: 'Idade Corporal',
    metabolismo_basal: 'Metabolismo Basal',
    gordura_visceral: 'Gordura Visceral',
    agua_corporal: 'Água Corporal',
    massa_muscular: 'Massa Muscular',
    massa_gorda: 'Massa Gorda',
    massa_ossea: 'Massa Óssea',
    db_anos: 'Anos',
    nivel: 'Nível',
    pollock_homem: 'Pollock 7 dobras (homens)',
    pollock_mulher: 'Pollock 7 dobras (mulheres)',
    referencias: 'Referências',
    tabela_acms: 'Tabela % de gordura ideal, ACMS - Lea & Febiger, 1986',
    tabela_acms_idades: 'Idade</div><div>18 a 29</div><div>30 a 39</div><div>40 a 49</div><div>50 a 59</div><div> > 60 ',
    tabela_acms_homens: 'Homens</div><div>14%</div><div>16%</div><div>17%</div><div>18%</div><div>21%',
    tabela_acms_mulheres: 'Mulheres</div><div>19%</div><div>21%</div><div>22%</div><div>23%</div><div>26%',
    tabela_imc: 'Tabela IMC pela Organização Mundial de Saúde',
    tabela_imc_baixo: 'Baixo</div><div class="value"> < 18,5',
    tabela_imc_baixo_tag: 'Obesidade leve</div><div class="value"> 30,0 a 34,9',
    tabela_imc_ideal: 'Ideal</div><div class="value"> 18,5 a 24,9',
    tabela_imc_ideal_tag: 'Obesidade moderada</div><div class="value"> 35,0 a 39,9',
    tabela_imc_sobre: 'Sobrepeso</div><div class="value"> 25,0 a 29,9',
    tabela_imc_sobre_tag: 'Obesidade mórbida</div><div class="value"> > 40',
    deletar_avaliacao: 'Deseja realmente apagar esta avaliação?',
    deletar_avaliacao_title: 'Apagar avaliação',
    deletar_avaliacao_sucesso: ': Os dados foram atualizados com sucesso!',
    salvar_avaliacao_sucesso: ': Os dados foram atualizados com sucesso!',
    obesidade_morbida: 'Obesidade mórbida',
    obesidade_mnoderada: 'Obesidade moderada',
    obesidade_leve: 'Obesidade leve',
    sobrepeso: 'Sobrepeso',
    idealpeso: 'Ideal',
    pesobaixo: 'Baixo',
    gorduraatual: 'gordura atual',
    pesogordo: 'peso gordo',
    pesomagro: 'peso magro',
    pesoideal: 'peso ideal',
    excesso: 'excesso',
    avaliacao_fisica: 'Avaliação Física',
    avaliacao_dia: 'Avaliação do dia',
    selecione_nova: 'Selecione uma avaliação ou adicione uma nova',
    nova_avaliacao: 'Nova avaliação',
    data_avaliacao: 'Data',
    peso: 'Peso',
    altura: 'Altura',
    idade: 'Idade',
    anos: 'anos',
    gorduraideal: 'Gordura ideal',
    gordura: 'gordura',
    imc: 'IMC',
    nenhuma_avaliacao: 'Nenhuma avaliação física cadastrada. Vamos começar?',
    medidas: 'Medidas',
    selecione_protocolo: 'Selecione o protocolo',
    dobras_cutaneas: 'Dobras Cutâneas',
    subescapular: 'Subescapular',
    tricipital: 'Tricipital',
    peitoral: 'Peitoral',
    axilarmedia: 'Axilar-média',
    suprailiaca: 'Supra-ilíaca',
    abdominal: 'Abdominal',
    coxa: 'Coxa',
    resultados: 'Resultados',
    massagorda: 'massa gorda',
    massamagra: 'massa magra',
    perimetros_centrais: 'Perímetros Centrais',
    ombros: 'Ombros',
    torax: 'Tórax',
    cintura: 'Cintura',
    quadril: 'Quadril',
    perimetros_perifericos: 'Perímetros Periféricos',
    direito: 'Direito',
    esquerdo: 'Esquerdo',
    antebraco: 'Antebraço',
    braco_relaxado: 'Braço relaxado',
    braco_contraido: 'Braço contraído',
    coxa_proximal: 'Coxa proximal',
    coxa_media: 'Coxa média',
    panturrilha: 'Panturrilha',
    voltar_avaliacoes: 'Voltar à lista de avaliações',
    salvar_avaliacao: 'Salvar avaliação',
  },

  FormComponent: {
    click_aqui: 'Clique aqui para enviar a foto',
    arraste_aqui: 'Arraste a foto até aqui',
    foto_removida_sucesso: 'A foto foi removida com sucesso!',
    senha_diferente: 'As senhas não conferem!',
    tel_branco: 'O telefone/whatsapp não pode estar em branco!',
    conta_atualizada_sucesso: 'Os dados da conta foram atualizados com sucesso!',
    criado_sucesso: ' criado com sucesso!',
    email_cadastrado: 'E-mail já existente! Favor cadastrar outro e-mail',
    apagar_conta: 'Deseja realmente apagar esta conta? Todo o progresso desta conta será apagado!',
    apagar_conta_title: 'Apagar conta',
    apagar_conta_sucesso: 'A conta foi apagada com sucesso!',
    foto_enviada_sucesso: 'A foto foi enviada com sucesso!',
    formulario: 'Formulário',
    limpar_formulario: 'Limpar Formulário',
    nome: 'Nome',
    email: 'Email',
    objetivo: 'Objetivo',
    senha: 'Senha (no mínimo 6 dígitos)',
    confirme_senha: 'Confirme a Senha',
    foto: 'Foto',
    remover_foto: 'Remover foto',
    info_adicional: 'Informações Adicionais',
    info_adicional_exp: 'Podemos entregar uma experiência melhor se nos contar um pouco mais sobre você',
    celular: 'Celular',
    ddi: 'DDI',
    telefone: 'Telefone/Whatsapp',
    ddd_numero: 'DDD + número',
    data_nascimento: 'Data de Nascimento',
    selecione_opcao: 'Selecione uma opção',
    altura: 'Altura (m)',
    peso: 'Peso (kg)',
    atencao: 'Atenção!',
    este_email: 'Este e-mail já consta em nossa base de dados.',
    favor_email: 'Favor cadastrar outro e-mail.',
  },

  CustomerAnamnesisComponent: {
    queixa_dor_cabeca: 'Dor de cabeça',
    queixa_dor_pescoco: 'Dor no pescoço',
    queixa_dor_peito: 'Dor no peito',
    queixa_dor_ombros: 'Dor nos ombros',
    queixa_dor_lombar: 'Dor na lombar',
    queixa_falta_ar_esforco: 'Falta de ar com esforço',
    queixa_falta_ar_repouso: 'Falta de ar em repouso',
    queixa_palpitacoes: 'Palpitações cardíacas',
    queixa_dor_joelhos: 'Dor nos joelhos',
    queixa_tontura: 'Tontura',
    queixa_sem_dores: 'Não tenho dores',
    doenca_diabetes: 'Diabetes',
    doenca_colesterol: 'Colesterol',
    doenca_pressao_alta: 'Pressão alta',
    doenca_nao_tem: 'Não tenho nada',
    fav_serie_trad: 'Séries tradicionais de musculação',
    fav_serie_circ: 'Séries circuitadas',
    fav_peso_corpo: 'Treinos com o peso do corpo',
    fav_peso_cardio: 'Alternar pesos e cardio',
    senha_invalida: 'Está senha não é válida. Tente novamente.',
    anamnese_add_sucesso: 'Anamnese registrada com sucesso!',
    anamnese_apagar: 'Deseja apagar esta anamnese?',
    anamnese_apagar_title: 'Apagar Anamnese',
    anamnese_apagar_sucesso: 'Anamnese apagada com sucesso!',
    questionario: 'Anamnese: responda o questionário de saúde',
    digite_senha: 'Digite sua senha para validar o acesso à anamnese.',
    precisa_senha: 'Você precisa digitar sua senha',
    validar: 'Validar',
    anamnese: 'Anamnese',
    avaliacao_saude: 'Anamnese - Avaliação de Saúde',
    responda: 'Por favor, responda o questionário da forma mais completa possível.',
    envie_link: 'Envie o link para o aluno acessar a anamnese.',
    anamnese_vazia: 'O aluno ainda não preencheu nenhuma anamnese',
    voltar_lista: 'Voltar à lista',
    queixas_atuais: 'Queixas atuais',
    outros: 'Outros',
    doencas_pre: 'Doenças pré-existentes',
    antecedentes_pessoais: 'Antecedentes Pessoais',
    cirurgias: 'Cirurgias?',
    traumas: 'Traumas?',
    antecedentes_familiares: 'Antecedentes Familiares',
    colesterol: 'Colesterol?',
    diabetes: 'Diabetes?',
    habitos: 'Hábitos de Vida',
    etilismo: 'Etilismo?',
    tabagismo: 'Tabagismo?',
    anticoncepcionais: 'Anticoncepcionais?',
    historico_treino: 'Histórico de Treino',
    quanto_tempo_treino: 'Há quanto tempo você treina?',
    quantas_vezes_treina: 'Quantas vezes na semana você pode treinar?',
    periodo_manha: 'Pela manhã',
    periodo_tarde: 'De tarde',
    periodo_noite: 'À noite',
    // periodo_possivel: 'É possível você treinar duas vezes em um único dia? Se sim, quais dias da semana seriam possíveis?',
    periodo_possivel: 'É possível você treinar duas vezes em um único dia? Se sim, quais dias da semana seriam possíveis? Quanto tempo por dia você tem disponível para treinar?',
    treino_gosta: 'Quais tipos de treinos você mais gosta?',
    sobre_treino_anterior: 'Algo que queira me contar sobre seu treino anterior? Descreva os detalhes:',
    data_inicio: 'Selecione a data que pretende iniciar os treinos',
    complemento_anamnese: 'Descreva algo importante que possa complementar essa anamnese.',
    apagar_anamnese: 'Apagar Anamnese',
    obrigado: 'Obrigado por responder essa avaliação de saúde.',
  },

  CustomerMenusComponent: {
    lista_prescricoes_atualizada: 'A lista de Prescrições foi atualizada.',
    ajuda_enviar_programas: '<p>Primeiro escolha o aluno que deseja editar. Assim a Lista de Programas e a guia Formulário ficarão disponíveis.</p>'
                          + '<p>Depois selecione os programas que deseja enviar para o cliente clicando na chave “oculto/selecionado”.</p>'
                          + '<p>O programa será enviado imediatamente.</p>'
                          + '<p>Envie quantos programas precisar.</p>'
                          + '<p>Se quiser ver a Lista de Espera novamente, clique no botão VOLTAR da guia Formulário.</p>',
    prescricoes: 'Prescrições',
    prescricoes_listadas: ' prescrições listadas',
    prescricao_listada: ' prescrição listada',
    disponiveis: 'disponíveis',
    selecionado: 'Selecionado',
    oculto: 'Oculto',
  },

  CustomerProgramsComponent: {
    lista_programas_atualizada: 'A lista de Programas do Aluno foi atualizada.',
    ajuda_enviar_programas: '<p>Primeiro escolha o aluno que deseja editar. Assim a Lista de Programas e a guia Formulário ficarão disponíveis.</p>'
                          + '<p>Depois selecione os programas que deseja enviar para o cliente clicando na chave “oculto/selecionado”.</p>'
                          + '<p>O programa será enviado imediatamente.</p>'
                          + '<p>Envie quantos programas precisar.</p>'
                          + '<p>Se quiser ver a Lista de Espera novamente, clique no botão VOLTAR da guia Formulário.</p>',
    programas: 'Programas',
    programas_listados: ' programas listados',
    programa_listado: ' programa listado',
    disponiveis: 'disponíveis',
    selecionado: 'Selecionado',
    oculto: 'Oculto',
    agenda: 'Agenda',
  },

  CustomerHistoryComponent: {
    historico: 'Histórico',
    historico_vazio: 'O histórico de atividades está vazio',
    concluido: 'Concluído',
    programa: 'Programa',
  },

  CustomerNotesComponent: {
    notas: 'Notas',
    notas_subtitulo: 'Estas são as últimas anotações do aluno dentro do app.',
    nenhuma_anotacao: 'Nenhuma anotação foi feita.',
  },

  CustomerFilesComponent: {
    arquivos: 'Arquivos',
    onde_visualizar_arquivo: 'Onde o aluno visualizará este arquivo?',
    todos_arquivos: 'Todos os arquivos.',
    remover_arquivo: 'Deseja remover este arquivo do aluno?',
    remover_arquivo_titulo: 'Remover arquivo',
    lista_arquivo_sucesso: 'A lista de Arquivos do Aluno foi atualizada.',
    ajuda_arquivos: '<p>Estes são todos os arquivos carregados na sua conta.</p>'
                  + '<p>Selecione os que deseja enviar para este aluno.</p>'
                  + '<p>Para carregar mais documentos, renomear ou excluir acesse a opção "Arquivos" no menu.</p>',
    selecionado: 'Selecionado',
    oculto: 'Oculto',              
  },

  NutricaoComponent: {
    alimento_alterado_sucesso: 'O Alimento foi alterado com sucesso!',
    alimento_cadastrado_sucesso: 'O Alimento foi cadastrado com sucesso!',
    alimento_clonado_sucesso: 'O Alimento foi clonado com sucesso!',
    alimento_apagar: 'Deseja realmente apagar este Alimento?',
    alimento_apagar_title: 'Apagar Alimento',
    alimento_apagar_sucesso: 'O Alimento foi apagado com sucesso!',
    ajuda: '<p>Primeiro escolha o aluno que deseja editar. Assim a Lista de Programas e a guia Formulário ficarão disponíveis.</p>'
         + '<p>Depois selecione os programas que deseja enviar para o cliente clicando na chave “oculto/selecionado”.</p>'
         + '<p>O programa será enviado imediatamente.</p>'
         + '<p>Envie quantos programas precisar.</p>'
         + '<p>Se quiser ver a Lista de Espera novamente, clique no botão VOLTAR da guia Formulário.</p>',
    formulario: 'FORMULÁRIO',
    formulario_limpar: 'LIMPAR FORMULÁRIO',
    novo_alimento: 'Novo Alimento',
    cadastre_alimentos: 'Cadastre os alimentos com este formulário.',
    editar_alimento: 'Editar Alimento',
    nome: 'Nome',
    unidades: 'Unidades',
    gramas: 'Gramas',
    mililitros: 'Mililitros',
    porcao: 'Porção',
    quantidade: 'Quantidade',
    energia: 'Energia',
    carboidratos: 'Carboidratos',
    acucares: 'Açúcares',
    proteinas: 'Proteínas',
    gorduras: 'Gorduras',
    gord_saturadas: 'Gorduras Saturadas',
    gord_monoinsaturadas: 'Gorduras Monoinsaturadas',
    gord_poliinstaturadas: 'Gorduras Poliinsaturadas',
    colesterol: 'Colesterol',
    fibras: 'Fibras',
    sodio: 'Sódio',
    potassio: 'Potássio',
    alimentos: 'ALIMENTOS',
    alimentos_listados: ' alimentos listados',
    alimento_listado: ' alimento listado',
    disponiveis: 'disponíveis',
    adicione_alimento: 'Adicione um alimento',
  },

  MenusComponent: {
    legend_title: 'Cálculo',
    extra_suplementos: 'Texto exemplo:\n\n- Whey protein isolado\n- Creatina monohidratada\n- Multivitaminico & Mineral\n- Ômega 3\n- UC II',
    extra_manipulados: 'Texto exemplo:\n\nFórmula 1:\n- Metilfolato (1mg)\n- Pridoxal 5 Fosfato (25mg)\n\nFórmula 2:- Curumina (300mg)\n- Piperina (3mg)',
    extra_compras: 'Texto exemplo:\n\n- Arroz integral\n- Batata doce\n- Mandioca\n- Filé de frango\n- Tilápia\n- Banana',
    menu_atualizado_sucesso: 'A prescrição foi atualizada com sucesso!',
    menu_cadastrado_sucesso: 'A prescrição foi cadastrada com sucesso!',
    menu_clonado_sucesso: 'A prescrição foi clonada com sucesso!',
    marca_dagua_mydietpro: 'MYDIETPRO EM MODO GRATUITO',
    marca_dagua_funcaex: 'FUNÇÃO EXCLUSIVA PARA ASSINANTES PRO',
    marca_dagua_stcoach: 'STCOACH EM MODO GRATUITO',
    proteinas: 'Proteínas',
    lipidios: 'Lipídios',
    carbo: 'Carboidratos',
    texto_livre: '[Texto livre até 10.000 caracteres]',
    nova_refeicao: 'Nova Refeição',
    refeicoes_geradas: 'Refeições geradas com sucesso!',
    nova_refeicao_sucesso: 'Nova refeição criada com sucesso!',
    refeicao_atualizada_sucesso: 'Refeição atualizada com sucesso!',
    clonar_refeicao: 'Deseja clonar esta refeição?',
    clonar_refeicao_title: 'Clonar refeição',
    clonar_refeicao_sucesso: 'Refeição clonada com sucesso!',
    remover_refeicao: 'Deseja remover esta refeição?',
    remover_refeicao_title: 'Apagar refeição',
    remover_refeicao_sucesso: 'Refeição removida com sucesso do sistema!',
    reordenar_refeicao: 'Refeições reordenadas com sucesso!',
    lista_atualizada: 'A lista de prescrições dos alunos foi atualizada.',
    lista_atualizada_pacientes: 'A lista de prescrições dos pacientes foi atualizada.',
    lista_arquivos_atualizada: 'A lista de Arquivos do Aluno foi atualizada.',
    modal_limite: 'Desculpe, você atingiu o limite máximo de cadastros permitidos nesta conta. '
                + 'Entre em contato com o administrador do sistema para obter mais informações '
                + 'ou para solicitar uma atualização do seu nível de acesso.',
    modal_limite_menus: 'Ops! você atingiu o limite de prescrições na sua conta atual. Faça um upgrade ou apague algumas prescrições antigas.',
    menus_title: 'Prescrições',
    nenhuma_prescricao: 'Nenhuma prescrição foi selecionada',
    um_clone_vez: 'Por favor, clone apenas uma prescrição por vez',
    um_pdf_vez: 'Por favor, gere o PDF de apenas uma prescrição por vez',
    gerando_pdf: 'Gerando o PDF, aguarde...',
    apagar_prescricoes: 'Deseja apagar as prescrições selecionadas?',
    apagar_prescricoes_title: 'Apagar Prescrições',
    escreva_deletar: 'escreva DELETAR, em caixa alta',
    escreva_deletar_input: 'DELETAR',
    apagar_prescricoes_sucesso: 'Os arquivos foram apagados com sucesso!',
    prescricoes: 'Prescrições',
    subtitulo_nutri: 'Crie planos nutricionais completos e envie para os seus pacientes.',
    subtitulo_prof: 'Crie planos nutricionais completos e envie para os seus alunos.',
    funcao_desktop: 'Função disponível para desktop.',
    funcao_desktop_sub: 'Atualmente, esta funcionalidade está disponível apenas para acesso por computadores. Estamos trabalhando ativamente para adaptar as telas para a versão mobile.',
    nova_prescricao: '+ Nova',
    gerar_pdf: 'Gerar PDF',
    clonar: 'Clonar',
    alimento: 'Alimento',
    alimentos: 'Alimentos',
    textos_livres: 'Textos livres',
    ultima_atualizacao: 'Última atualização foi feita em',
    tipo: 'Tipo',
    kcal: 'KCal',
    prot: 'Prot.',
    carb: 'Carb.',
    gord: 'Gord.',
    selecione_pacientes: 'Selecione pacientes para esta prescrição',
    selecione_alunos: 'Selecione alunos para esta prescrição',
    enviar_todos: 'Enviar para todos',   
    selecionado: 'Selecionado',
    ocultado: 'Ocultado',
    sem_menus: 'Não há planos nutricionais cadastrados.',
    voltar_tela: 'Voltar à tela anterior',
    atualizar_prescricao: 'Atualizar Prescrição',
    nome_obrigatorio: 'Dê um nome para essa prescrição (obrigatório)',
    continuar: 'Continuar',
    como_deseja: 'Como deseja prescrever?',
    menu_1_desc: 'A prescrição é feita escolhendo os alimentos da nossa base de dados. Essa forma considera cálculos de macro e micronutrientes.',
    menu_2_desc: 'A prescrição da dieta é feita por meio de texto livre, sem considerar cálculos. No entanto, é possível inserir os valores manualmente.',
    menu_1: 'Prescreva as refeições com alimentos da base de dados. Edite o que desejar.',
    menu_2: 'Prescreva as refeições com textos livres. Edite o que desejar.',
    gerar_ref_inicial: 'Gerar lista inicial de refeições',
    alimento_adicionar: 'Adicione um alimento da base de dados',
    alimento_texto_livre: 'Adicione um texto livre',
    alimento_duplicar: 'Duplicar esta refeição',
    alimento_apagar: 'Apagar esta refeição',
    hora: 'Hora',
    nome_menu: 'Nome da prescrição (Português)',
    nome_menu_en: 'Nome da prescrição (Inglês)',
    nome_menu_es: 'Nome da prescrição (Espanhol)',
    qtd: 'qtd',
    unidade: 'unidade',
    lipid: 'Lipid.',
    calorias: 'calorias',
    porcao: ' Porção',
    porcoes: ' Porções',
    total_macro: 'Total de macronutrientes',
    sem_alimentos: 'Refeição sem alimentos',
    sem_descricao: 'Refeição sem descrição',
    nutrientes: 'Nutrientes',
    acucares: 'Açúcares',
    fibras: 'Fibras',
    sodio: 'Sódio',
    potassio: 'Potássio',
    colesterol: 'Colesterol',
    gd_sat: 'Gd. Saturada',
    gd_monosat: 'Gd. Monoinsaturada',
    gd_polisat: 'Gd. Poliinsaturada',
    gd_total: 'Gd. Total',
    atualizar_nutrientes: 'Atualizar nutrientes',
    suplementos: 'Suplementos',
    manipulados: 'Manipulados',
    lista_compras: 'Lista de compras',
    quantidade: 'Quantidade',
    relatorio_nutrientes: 'Relatório de Nutrientes',
    refeicao: 'Refeição',
    total: 'Total',
    atualizar_crn_title: 'Ação necessária!',
    atualizar_crn: 'Para usar a função de prescrição nutricional é necessário o preenchiemento dos dados do profissional de nutrição.',
    atualizar_crn_bt_ok: 'Preencher dados',
 },

  ArquivosComponent: {
    carregue_arquivo: 'CARREGUE O ARQUIVO',
    arquivo_maximo: 'O arquivo deve ter no máximo ',
    remover_arquivo: 'Deseja remover este arquivo?',
    remover_arquivo_titulo: 'Apagar arquivo',
    remover_arquivo_sucesso: 'Arquivo removido com sucesso do sistema!',
    renomear_arquivo: 'Renomear arquivo',
    renomear_arquivo_sucesso: 'Arquivo atualizado com sucesso!',
    lista_alunos_atualizada: 'A lista de Arquivos do Aluno foi atualizada.',
    ajuda_todos_arquivos: '<p>Estes são todos os arquivos carregados na sua conta.</p>'
                        + '<p>Selecione os que deseja enviar para este aluno.</p>'
                        + '<p>Para carregar mais documentos, renomear ou excluir acesse a opção "Arquivos" no menu.</p>',
    arquivo_grande: 'O arquivo é maior que o espaço de armazenamento disponivel!',
    sem_espaco: 'Você não tem mais espaço de armazenamento disponivel!',
    arquivo_envio_sucesso: 'Seu arquivo foi enviado com sucesso!',
    nenhum_selecionado: 'Nenhum arquivo foi selecionado',
    apagar_arquivos: 'Deseja realmente apagar os arquivos selecionados?',
    apagar_arquivos_titulo: 'Apagar Arquivos',
    apagar_arquivos_placeholder: 'escreva DELETAR, em caixa alta', 
    apagar_arquivos_confirmacao: 'DELETAR',
    apagar_arquivos_sucesso: 'Os arquivos foram apagados com sucesso!',
    ajuda_legenda: 'Onde o aluno visualizará este arquivo?',
    recurso_exclusivo: 'Recurso disponível apenas para assinantes do plano Standard ou superior.',
    armazenamento_maximo: 'Armazenamento máximo',
    total_usado: 'Total Usado',
    precisa_mais: 'Precisa de mais espaço de armazenamento?',
    faca_upgrade: 'faça um upgrade no seu plano!',
    lista: 'Lista',
    lista_vazia: 'Sua lista de Arquivos está vazia.',
    enviado: 'Enviado',
    atualizado: 'Atualizado',
    alunos_selecionados: 'Alunos Selecionados',
    selecione_alunos: 'Selecione alunos para este documento',
    enviar_todos: 'Enviar para todos',
    selecionado: 'Selecionado',
    ocultado: 'Ocultado',
    nenhum_arquivo: 'Não há arquivos cadastrados.',
  },

  BibliotecaComponent: {
    exercicio_atualizado_sucesso: 'Exercício atualizado com sucesso!',
    exercicio_cadastrado_sucesso: 'O Exercício foi cadastrado com sucesso!',
    apagar_exercicio: 'Deseja realmente apagar este Exercício?',
    apagar_exercicio_title: 'Apagar Exercício',
    apagar_exercicio_sucesso: 'O Exercício foi apagado com sucesso!',
    nenhum_selecionado: 'Nenhum exercício foi selecionado',
    apagar_exercicio_certeza: 'Deseja realmente apagar os exercícios selecionados?',
    apagar_exercicio_certeza_title: 'Apagar',
    apagar_exercicio_certeza_placeholder: 'escreva DELETAR, em caixa alta',
    apagar_exercicio_certeza_confirmacao: 'DELETAR',
    apagar_exercicio_certeza_sucesso: 'O exercício foi apagado com sucesso!',
    apagar_exercicios_certeza_sucesso: 'Os exercícios foram apagados com sucesso!',
    banner_enviado_sucesso: 'Banner enviado com sucesso. Não esqueça de salvar o Exercício!',
    biblioteca_ajuda: '<h4>Adicione até 300 vídeos.</h4>'
                    + '<p>Nesta seção você poderá organizar organizar até 300 vídeos de exercícios para usá-los nas montagens dos treinos.</p>'

                    + '<h4>Recurso Pro</h4>'
                    + '<p>Os vídeos desta biblioteca só podem ser usados por assinantes a partir do plano Pro.</p>'

                    + '<h4>Vídeos</h4>'
                    + '<p>A montagem da biblioteca deverá ser através dos links do YouTube ou códigos do Vimeo. <a href="https://www.stcoach.app/tutoriais-videos" target="_blank">Clique aqui</a> para saber mais.</p>'

                    + '<h4>Imagens</h4>'
                    + '<p>As miniaturas dos exercícios são opcionais. Se for adicionar, siga as recomendações:</p>'
                    + '<ul><li>Formato JPG</li>'
                    + '<li>Dimensão 300x300px</li></ul>',
    exclusivo_pro_plus: 'Esse recurso é exclusivo de assinantes Pro ou Black. Gostaria de dar um upgrade no seu plano?',
    exclusivo_pro_plus_title: 'Recurso Exclusivo',
    minha_biblioteca: 'Minha Biblioteca',
    biblioteca_vazia: 'Não há exercícios cadastrados na biblioteca.',
    biblioteca_procurando: 'Buscando exercícios da biblioteca...',
    novo_exercicio: 'Novo exercício',
    editar_exercicio: 'Editar exercício',
    antes_enviar_banner: 'Antes de enviar um banner, adicione um TÍTULO ao Exercício.',
    enviar_mini: 'Clique aqui para enviar a miniatura',
  },

  ProfessoresComponent: {
    clique_envie_foto: 'Clique aqui para enviar sua foto',
    arraste_foto: 'Arraste sua foto até aqui',
    clique_envie_logo: 'Clique aqui para enviar sua logo',
    arraste_logo: 'Arraste sua logo até aqui',
    dados_atualizados_sucesso: 'Seus dados foram atualizados com sucesso!',
    conta_ativada_sucesso: 'Sua conta foi ativada com sucesso!',
    cancelamento_plano: 'Cancelamento do plano.',
    cancelamento_plano_teacher: ''
                              + '<center><p><b>Ao cancelar o plano, sua conta passará a ser gratuita.</b></p></center>'
                              + '<center><p><b>Você perderá acesso aos treinos criados e seus alunos perderão acesso ao app.</b></p></center>',
    cancelamento_plano_nutri: ''
                            + '<center><p><b>Ao cancelar a assinatura, sua conta passará a ser gratuita imediatamente.</b></p></center>'
                            + '<center><p><b>Você perderá acesso às dietas que excederam o plano grátis.</b></p></center>',
    cancel_plano_bt_ok: 'Manter o plano',
    cancel_plano_bt_cancel: 'Confirmar cancelamento',
    ativar_conta: '<p>Por medida de segurança, precisamos confirmar a ativação da sua conta antes de prosseguir. Essa ativação é automática e não é necessário nenhuma ação adicional.</p> <p>Deseja ativar sua conta?</p>',
    ativar_conta_title: 'Ativar conta',
    plano_atual: ''
              + '<center><p><b>Este é o seu plano atual.</b></p></center>'
              + '<center><p><b>Ao cancelar o plano, sua conta passará a ser gratuita.</b></p></center>'
              + '<center><p><b>Você perderá acesso aos treinos criados e seus alunos perderão acesso ao app.</b></p></center>',
    plano_atual_bt_ok: 'Manter o plano',
    plano_atual_bt_cancel: 'Confirmar cancelamento',
    plano_superior: 'Seu plano atual é superior ao escolhido. Deseja migrar para esse plano? Você terá menos recursos disponíveis e todos os alunos e programas excedentes serão desabilitados.',
    plano_superior_title: 'Assinar novo plano',
    plano_nao_selecionado: 'O plano da assinatura não foi selecionado!',
    cc_branco: 'O número do cartão de crédito está em branco!',
    cvc_branco: 'O código de segurança do cartão de crédito está em branco!',
    expira_mes_branco: 'O campo do mês de expiração do cartão de crédito está em branco!',
    expira_ano_branco: 'O campo do ano de expiração do cartão de crédito está em branco!',
    erro_reload: 'Ocorreu um erro e iremos recarregar a página.',
    verifica_pagamento: 'A verificação do pagamento foi concluída e seu plano atualizado',
    aluno_desativado: 'O Aluno foi desativado com sucesso!',
    aluno_ativado: 'O Aluno foi ativado com sucesso!',
    aluno_bloqueado: 'O Aluno foi bloqueado com sucesso!',
    ajuda_assinatura: ''
                    + '<h3>Assinatura</h3>'
                    + '<p>- Sistema de assinatura recorremente mensal (renovação automática).</p>'
                    + '<p>- Pagamento apenas por cartão de crédito.</p>'
                    + '<p>- Para substituir o plano, basca clicar no desejado e assinar.</p>'
                    + '<br/>'
                    + '<h3>Dúvidas</h3>'
                    + '<p>1. Como faço pra trocar de plano?</p>'
                    + '<p>Basta clicar no plano desejado e assinar novamente. A assinatura antiga será automaticamente cancelada e a nova entrará em vigor. Apenas procure fazer o upgrade faltando poucos dias para a renovação automática, pois os valores não são compensados automaticamente pelo sistema.</p>'
                    + '<p>2. Se cancelar, perderei meu trabalho e meus alunos?</p>'
                    + '<p>Não. Caso cancele a assinatura ou o cartão de crédito não autorize a renovação, no sistema ocorrerá o bloqueio de acessos aos alunos e programas. No app, seus alunos perderão o acesso ao login.</p>'
                    + '<p>Todos os cadastros dos alunos, lista de espera, bem como os programas criados permanecerão no sistema até que a assinatura seja reativada.</p>',
    avatar_enviado_sucesso: 'Seu avatar foi enviado com sucesso!',
    logo_enviado_sucesso: 'Sua logo foi enviada com sucesso!',
    foto_removida_sucesso: 'Sua foto foi removida com sucesso!',
    perfil: 'Perfil',
    subtitulo: 'Escolha a logo que será exibida no app.',
    remover_foto: 'Remover foto',
    minha_logo: 'Minha Logo',
    minha_logo_sub: 'Escolha a logo que ficará exposta no sistema e no app do',
    exclusividade: 'Exclusividade',
    logo_pdf: 'Logo para PDF',
    logo_pdf_sub: 'Escolha a logomarca que será exibida no cabeçalho do PDF (fundo branco).',
    exclusivo_pro_black: 'Exclusivo para assinantes <span>PRO e Black</span>',
    exclusivo_gold: 'Exclusividade <span>GOLD</span>',
    dados: 'Dados',
    info_basica: 'Suas informações básicas.',
    ddd_numero: 'DDD + número',
    selecione_estado: 'Selecione um Estado',
    cidade: 'Cidade',
    selecione_opcao: 'Selecione uma opção',
    cref: 'CREF - Profissional responsável pelas prescrições dos treinos.',
    crn: 'CRN - Profissional responsável pelas prescrições nutricionais.',
    cpf: 'CPF',
    crefito: 'CREFITO',
    nutriname: 'Nome do profissional de nutrição',
    cpf_not_valid: 'O CPF não foi digitado corretamente!',
    cpf_empty: 'O campo CPF não pode ficar vazio!',
    cref_not_empty: 'O campo CREF não pode ficar vazio!',
    crefito_not_empty: 'O campo CREFITO não pode ficar vazio!',
    crn_not_empty: 'O campo CRN não pode ficar vazio!',
    nutriname_not_empty: 'O campo "Nome do profissional de nutrição" não pode ficar vazio!',
    sou_estrangeiro: 'Sou estrangeiro e declaro que estou qualificado para praticar a profissão de acordo com as leis do meu país.',
    nutriname_igual_nome: 'Sou o nutricionista responsável',
    atividade: 'Atividade',
    edfisica: 'Profissional de Educação Física',
    fisio: 'Fisioterapeuta',
    nutri: 'Nutricionista',
    estudante: 'Estudante',
    senha: 'Senha (no mínimo 6 dígitos)',
    senha_sub: 'Apenas altere esses campos se quiser trocar sua senha.',
    confirme_senha: 'Confirme a Senha',
    apagar_conta: 'Apagar Conta',
    planos_assinatura: 'Planos de Assinatura',
    planos_sub: 'Escolha o plano que melhor se encaixa no seu perfil',
    mes: 'mês',
    p_gratis: 'Grátis',
    p_gratis_alunos: '1 Aluno',
    p_gratis_programas: '2 Programas',
    p_gratis_capas: 'Capas Básicas',
    p_basic: 'Basic',
    p_standard: 'Standard',
    p_pro: 'Pro',
    p_black: 'Black',
    p_lite: 'Lite',
    p_gold: 'Gold',
    p_alunos: 'Alunos',
    p_programas: 'Programas',
    p_capas: 'Capas Básicas',
    p_capas_e: 'Capas Especiais',
    p_arquivos: 'para arquivos',
    p_avaliacao: 'Avaliação Física',
    p_sualogo: 'Sua logo no app',
    p_nutri: 'Nutrição',
    p_mylib: 'Minha Biblioteca',
    p_bkp: 'Backup de Segurança',
    p_pdf: 'Converter treinos em PDF',
    p_pacientes: 'Pacientes',
    p_prescricoes: 'Prescrições',
    p_link: 'Link de captação',
    p_pacientes_ili: 'Pacientes ilimitados',
    p_prescricoes_ili: 'Prescrições ilimitados',
    p_gratuito: 'Plano Gratuito',
    capas: '*Os planos que tem acesso às capas especiais também tem acesso às capas básicas.',
    meu_plano_atual: 'Meu plano atual',
    gerenciar: 'Gerenciar assinatura',
    plano_prepago: 'Plano pré-pago',
    expiracao: 'Tempo para expiração do plano atual',
    renovacao: 'Esse tipo de plano requer renovação todos os meses. Ele não é renovado automaticamente.',
    total_alunos: 'Total de Alunos cadastrados',
    ativos: 'Ativos',
    inativos: 'Inativos',
    total_programas: 'Total de Programas',
    arquivos: 'Arquivos',
    total_arquivos: 'Total de Arquivos',
    total_pacientes: 'Total de pacientes cadastrados',
    total_prescricoes: 'Total de Prescrições',
    assinatura_recorrente: 'Assinatura recorrente',
    renovacao_automatica: 'Com renovação automática a cada 30 dias',
    cc_nome: 'Nome no Cartão de Crédito',
    cc_numero: 'Número no Cartão de Crédito',
    cc_cvv: 'CVV',
    cc_data_exp: 'Data de Expiração',
    cc_mes: 'Mês',
    cc_ano: 'Ano',
    cc_doisultimos: '(dois últimos dígitos)',
    limpar_campos: 'Limpar Campos',
    assinar: 'Assinar',
    pagamento_pix: 'Pagamento por PIX',
    sem_renovacao: 'Sem renovação automática',
    pelo_computador: 'Recomendamos que este meio de pagamento seja feito pelo computador.',
    pagar: 'Pagar',
    gerando_link: 'Gerando o link de pagamento...',
    aguarde: 'Por favor, aguarde alguns instantes',
    link_sucesso: 'Link gerado com sucesso',
    pag_instrucoes: 'Clique no botão abaixo e siga as instruções de pagamento para pix, boleto ou cartão de crédito.',
    pag_demora: 'O pagamento feito por pix ou cartão de crédito leva 2 minutos para liberar as funções.',
    pag_boleto_demora: 'O pagamento feito por BOLETO pode levar até 72h para ser compensado e processado.',
    pag_aguarde: 'Favor aguardar os períodos antes de abrir uma reclamação conosco.',
    link_abrir: 'Abrir Link de Pagamento',
    cancelar_voltar: 'Cancelar e voltar',
    historico: 'Histórico',
    desc_hist_stcoach: 'Aqui você pode ver seus últimos pagamentos no STCoach.',
    desc_hist_mydiet: 'Aqui você pode ver seus últimos pagamentos no MyDietPro.',
    verificando: 'Verificando suas compras...',
    expirado: 'Expirado',
    processando: 'Processando',
    pago: 'Pago',
    minha_conta: 'Meu Plano',
    aplicativos: 'Aplicativos',
    criado_em: 'Criado em',
    ultima_atual: 'Última atualização',
    avisos_legais: 'Avisos Legais',
    termos_uso: 'Termos de Uso',
    politiva_priv: 'Política de Privacidade',
    politica_cancel: 'Política de Cancelamento',
    app_professor: 'Aplicativo do Professor',
    app_prof_sub: 'Baixe o aplicativo exclusivo para receber notificações.',
    app_aluno: 'Aplicativo do Aluno',
    app_aluno_sub: 'Baixe o aplicativo do aluno para ver como é fluxo de treino.',
    copie_envie: 'Copie e envie rápido para aos alunos',
  },

  DialogI18nComponent: {
    campo_vazio: 'O campo que deseja traduzir está vazio!',
    traduza_texto: 'Traduza o texto',
    em_ingles: 'Em Inglês',
    em_espanhol: 'Em Espanhol',
    traduzir: 'Traduzir',
    add_traducoes: 'Adicionar traduções',
  },

  CustomerWaitlineComponent: {
    link_transferencia: 'O link foi copiado para Área de Transferência',
    copia_negada: 'Seu sistema não permite a cópia automatizada. Copie e cole manualmente.',
    alterar_sauda: 'Tem certeza que deseja alterar a saudação da sua lista de espera?',
    alterar_sauda_title: 'Alterar saudação',
    alterar_sauda_sucesso: 'Sua saudação foi atualizada com sucesso!',
    saudacao_padrao: ''
                    + '<p>Frase de saudação após o aluno abrir o link de pré-cadastro.</p>'
                    + '<p>Capriche na frase ;)</p>',
    saudacao_ajuda: ''
                    + '<p>Para obter alunos na lista, divulgue o link acima em seu site e nas suas redes sociais.</p>'
                    + '<p>Os alunos pré-cadastrados não somarão como alunos efetivos no seu plano, exceto se forem aceitos.</p>'
                    + '<p>Procure aceitar possíveis alunos para um período grátis e aumentar suas chances de conversão.</p>',
    lista_espera: 'Lista de Espera',
    link_captacao: 'Link de captação',
    saudacao: 'Saudação',
    concordo: 'Ao me cadastrar, declaro que li aceito',
    privacidade: 'a política de privacidade',
    atencao: 'Atenção!',
    este_email: 'Este e-mail já consta em nossa base de dados.',
    favor_email: 'Favor cadastrar outro e-mail.',
  },

  DashboardFeedbacksComponent: {
    feedbacks_title: 'Feedbacks dos alunos',
    feedback_title: 'Feedback',
    titulo_programa: 'Título do programa',
    titulo_treino: 'Título do treino',
    aluno: 'Aluno',
    nome_programa: 'Nome do Programa',
    nome_treino: 'Nome do Treino',
    feedback_data: 'Data',
    feedback: 'Feedback',
    nenhum_feedback: 'Nenhum feedback enviado',
    nenhum_feedback_load: 'Nenhum feedback carregado',
    ver: 'Ver',
    ver_7: 'Ver 7 dias',
    ver_15: 'Ver 15 dias',
    ver_30: 'Ver 30 dias',
    ver_todos: 'ver todos',    
  }
};