import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';

import { CustomerProvider } from '../../../providers/customer/customer';
import { GoalProvider } from '../../../providers/goal/goal';
import { AuthService } from '../../../providers/auth/auth.service';
import { UserProvider } from '../../../providers/user/user';
import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { I18nProvider } from '../../../providers/i18n/i18n';


import { codes } from '../../../providers/helpers/countriesJson_ptBR';

@Component({
  selector: 'app-alunos-precad',
  templateUrl: './precad.component.html',
  styleUrls: ['./precad.component.scss']
})
export class AlunosPrecadComponent implements OnInit {
  PAGE: any;
  STRINGS: any;

  protected headers: any;
  teacherCode: string = '';

  loading: boolean = false;
  expired: boolean = false;
  showForm: boolean = false;
  done: boolean = false;
  studentGender: string = '';
  userBirthdate: any;
  userGoalId: number;
  goals: any = [];
  teacher: any = [];
  route_id: string = '0';
  codes = codes;

  studentSelected: boolean = false;
  studentId: number = 0;
  studentForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    status: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    goal_id: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    password_confirmation: new FormControl('', [Validators.required]),
    details: new FormGroup({
      ddi: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      birthdate: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      height: new FormControl('', [Validators.required]),
      weight: new FormControl('', [Validators.required]),
    }),
  });


  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public auth: AuthService,
    public toastr: ToastrService,
    public api: ApiProvider,
    public goalProvider: GoalProvider,
    public studentProv: CustomerProvider,
    public userProv: UserProvider,
    public helpers: HelpersProvider,
    public i18n: I18nProvider,
  ) {
    const qs = this.helpers.getUrlParam();
    if (qs.l !== null) this.helpers.changeLang(qs.l);

    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('CustomerWaitlineComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    // this.teacherCode = this.route.snapshot.params.code;

    this.route.queryParams.subscribe(
      (params: any) => {
        // Defaults to 0 if no query param provided.
        this.teacherCode = params['code'] || '';
      }
    );

  }

  ngOnInit() {
    this.codes = this.helpers.sortByProperty(codes, 'nome');
    this.studentForm.reset();
    this.showForm = true;
    this.studentSelected = false;
    this.studentId = 0;
    this.studentForm.patchValue({ status: 8, details : { ddi: '0055', gender: 'm'} });
    this.getTeacherGreeting();
  }

  getTeacherGreeting() {
    this.loading = true;
    // busca os objetivos dos alunos
    this.goalProvider.indexOpen().then(
      (res: any) => {
        this.goals = res.goals;

        // busca o avatar e saudacao do professor
        this.userProv.getTeacherGreeting(this.teacherCode).then(
          (res: any) => {
            this.loading = false;
            this.teacher = res.teacher;
          },
          (err: any) => {
            this.toastr.error(err, '', this.helpers.toastrCfgs);
            this.teacher = null;
            this.loading = false;
          }
        );

      },
      (err: any) => {
        console.log(err);
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  new() {
    this.studentForm.reset();
    this.showForm = true;
    this.studentSelected = false;
    this.studentId = 0;
    this.studentForm.patchValue({ status: 2, details : { ddi: '0055', gender: 'm'} });
  }


  save() {
    this.validateAllFormFields(this.studentForm);

    if (this.studentForm.value.password !== this.studentForm.value.password_confirmation) {
      this.toastr.error(this.STRINGS.RegistrationPage.senha_diferente, '', this.helpers.toastrCfgs);
      return false;
    }

    const dataString = JSON.stringify(this.studentForm.value);
    const data = JSON.parse(dataString);
    if (data.details.birthdate) {
      data.details.birthdate = data.details.birthdate.substring(-10, 10);
    }
    data.user_id = this.teacher.id;

    if (data.goal_id === null) data.goal_id = 1;

    this.loading = true;
    this.studentProv.createCustomer(data).then(
      (_user: any) => {
        this.loading = false;
        // this.toastr.success('Pedido registrado com sucesso!');
        this.done = true;
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        // this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        if (err.err = 'Já existe uma conta com esse email!') {
            // this.toastr.error(this.PAGE.email_cadastrado, '', this.helpers.toastrCfgs);
            let msg = '';
            msg += '<h2>'+this.PAGE.atencao+'</h2>';
            msg += '<center>'+this.PAGE.este_email+'</center>';
            msg += '<center>'+this.PAGE.favor_email+'</center>';
        
            const dialogRef = this.dialog.open(DialogLightComponent, { width: '30vw', data: { message: msg, bt_ok: 'Ok' } });
            dialogRef.afterClosed().subscribe(_result => {});  
        }
      }
    );

  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return this.STRINGS.campo_invalido;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  toggleClass(item: any) {
    item.active = !item.active;
  }


  helpWaitline() {
    let msg = this.PAGE.saudacao_ajuda;

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }


}
